import React from 'react';
import { Dropdown, Menu, Space, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FilterCheckbox = ({
    items,
}) => {
    return (
        <Dropdown overlay={
            <Menu
                items={items.map(item => {
                    return {
                        key: item.key,
                        label: (
                            <Checkbox>{item.label}</Checkbox>
                        )
                    }
                })}
            />
        }>
            <Space>
                Hover me
                <DownOutlined/>
            </Space>
        </Dropdown>
    )
};

export default FilterCheckbox;