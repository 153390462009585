import logo from "../../assets/img/logo.svg";
import "./style.less";

const Logo = () => {
    return (
        <div className="logo-container">
            <a href="/">
                <img className="logo-image" src={logo} alt="logo" />
            </a>
        </div>
    );
};

export default Logo;
