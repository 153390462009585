const appConfig = {
    prefix: 'pp',
    imageMaxUploadSize: 1_000_000, //  bytes/10 Megabytes
    layout: {
        sideMenu: {
            width: 250,
        }
    }
}

export default appConfig;