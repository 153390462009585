import * as React from "react";
import axios from "axios";
import "./style.less";
import { Login as LoginForm, ForgotPassword } from "../../views/auth";
import loginImage from "../../assets/img/login-image.jpg";
import message from "../../components/message";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [pageType, setPageType] = React.useState("login");

  	const handleLogin = (values) => {
		setLoading(true);
		axios.post(process.env.REACT_APP_BASE_URL + "/auth/tetgeleg/requestToken", values)
        .then(function (response) {
            localStorage.setItem("menu", JSON.stringify(response.data.result.menu));
            localStorage.setItem("first_name", response.data.result.firstName);
            localStorage.setItem("last_name", response.data.result.lastName);
            localStorage.setItem("roleName", response.data.result.roleName);
            localStorage.setItem("email", response.data.result.email);
            localStorage.setItem("phoneNumber", response.data.result.phoneNumber);
            localStorage.setItem("token", response.data.result.token);
            setLoading(false);
            window.location.href = "/dashboard";
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false);
        });
	};

	const onPasswordResetFinish = (text) => {
		setPageType("login");
		message(text, true);
	};

	const getForm = () => {
		switch (pageType) {
			case "login": {
				return (
					<LoginForm
						onFinish={handleLogin}
						loading={loading}
						loginTypeChange={setPageType}
					/>
				);
			}
			case "forgot": {
				return (
					<ForgotPassword
						onFinish={onPasswordResetFinish}
						loginTypeChange={setPageType}
					/>
				);
			}
			default: return null;
		}
	};

	return (
		<div className="login-container">
			<div
				style={{
					flex: 5,
					backgroundImage: `url(${
						loginImage
					})`
				}}
				className="login-image"
			/>
			{getForm()}
		</div>
	);
};

export default Login;
