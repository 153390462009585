import * as React from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClickOutside) {
  const handleClickOutside =  React.useCallback(
      (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      },
      [ref, onClickOutside]
  );
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideListener({ onClickOutside = () => undefined, children }) {
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, onClickOutside);

  return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideListener;
