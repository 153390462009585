import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import { API_URL, TITLE } from "./index";

const AppVersionEdit = () => {
	const { pathname } = useLocation();
	const history = useHistory();

	const [ fields, setFields ] = React.useState([]);

	React.useEffect(() => {
		const fetchFields = async () => {
			try {
				const { data = {} } = await axios.get(`${API_URL}`);
				const { result = [] } = data;
				setFields(result ?? []);
			} catch (error) {
				console.log(`Fetch ${API_URL}: `, error.message);
			}
		}
		void fetchFields();
	}, []);

	const handleSubmit = async fields => {
		try {
			const { data } = await axios.post(`${API_URL}/${fields.osType}`, fields);
			if (data.statusCode === 200) {
				if (pathname.endsWith('/edit')) {
					const charCount = '/edit'.length;
					const path = pathname.slice(0, -1 * charCount)
					history.push(path);
				}
			}
		} catch (error) {
			console.log(`${API_URL} Submit Error: `, error.message);
		}
	}

	const handleCancel = () => {
		history.push(-1);
	};

	const getFields = () => {
		return fields.map(field => {
			return {
				...field
			}
		})
	}

	return (
		<PageContent
			background='#ffffff'
			subheader={{
				title: `${TITLE} үүсгэх`,
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={getFields()}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</PageContent>
	)
}

export default AppVersionEdit;