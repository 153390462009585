import * as React from "react";
import moment from "moment";
import _ from 'lodash';
import { Form, Select, Input, Switch, Button, Row, InputNumber, DatePicker, Upload, Tooltip, Tag, Card, Modal, List } from "antd";
import { UploadOutlined, PlusOutlined, CloseOutlined, FormOutlined } from "@ant-design/icons";
import "./customform.less";
import { reducer, initialState } from "./reducer";
import * as actions from './reducer/action';
import Meditor from '../editor/Meditor';
import { getNew, validateImage } from "./helper";
import message from '../message';
import TetgelegIconSelect from "./TetgelegIconSelect";

// const colors = [
// 	'95D1CC',
// 	'5584AC',
// 	'FFAFAF',
// 	'D3E4CD',
// 	'F4BEEE'
// ];
// https://coolors.co/palettes/trending
// https://colorhunt.co/
// Tags can be colorized.

const CustomForm = ({ 
	fields = [], 
	onCancel = () => undefined, 
	onSubmit = () => undefined,
	imageUpload = () => {
		console.error('Assign Image Upload handler');
	},
}) => {
	const [form] = Form.useForm();

	const [ state, dispatch ] = React.useReducer(reducer, initialState, undefined);

	React.useEffect(() => {
		const values = {};
		const tagsClone = _.cloneDeep(state.tags);
		const embeddedValues = [];
		for (const field of fields) {
			if (field.hasOwnProperty('value')) {
				let value = field.value;
				if (['date', 'datetime'].includes(field.instance)) {
					value = moment(field.value);
				}
				values[field.key] = value;
			}
			if (field.instance === 'array' && !field.enum) {
				if (!tagsClone[field.key]) {
					tagsClone[field.key] = {
						inputValue: '',
						value: field.value ?? [],
					}
				}
			};
			if (field.instance === 'array' && field.arrayType === 'embedded' && field.hasOwnProperty('value')) {
				embeddedValues.push(field);
			};
		};
		if (embeddedValues.length) {
			dispatch({
				type: actions.embeddedTypeValueUpdate,
				payload: {
					embeddedValues,
				}
			})
		}
		dispatch({
			type: actions.tagChange,
			payload: tagsClone
		});
		form.setFieldsValue(values);
	}, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

	const getFormItem = (field, index) => {
		const customProps = field?.customProps ?? {};
		const formProps = {
			key: index,
			name: field.key,
			label: field.name,
			rules: [
				{
					required: field.instance !== "boolean" && !!field.required,
					message: `${field.name} дутуу байна!`,
				},
			],
			...customProps
		};
		const disabled = field.editLock || field.editable === false;

		if (field.hasOwnProperty("enum")) {
			// This means field is Dropdown/Select
			const options = [...field.enum];
			let filterOption;
			if (field.enumDictionary) {
				filterOption = (inputValue, option) => {
					const displayValue = field.enumDictionary?.[option.value] ?? option.value;
					try {
						return displayValue.toLowerCase().includes(inputValue.toLowerCase());	
					} catch (e) {
						console.log('select filter err>>', e);
						return true;
					}
				};
			} else {
				filterOption = field.filterOption;
			};

			return (
				<Form.Item {...formProps}>
					<Select 
						showSearch
						disabled={disabled} 
						mode={field.instance === "array" ? "multiple" : undefined}
						filterOption={filterOption}
					>
						{options?.map((option) => {
							return (
								<Select.Option key={option} value={option}>
									{field.enumDictionary?.[option] ?? option}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			);
		}
		switch (field.instance) {
			case "string": {
				return (
					<Form.Item {...formProps}>
						<Input disabled={disabled} />
					</Form.Item>
				);
			}
			case "boolean": {
				return (
					<Form.Item {...formProps} valuePropName="checked" initialValue={false}>
						<Switch />
					</Form.Item>
				);
			}
			case "number": {
				return (
					<Form.Item {...formProps}>
						<InputNumber disabled={disabled} />
					</Form.Item>
				);
			}
			case "date": {
				return (
					<Form.Item {...formProps}>
						<DatePicker 
							disabled={disabled} 
							disabledDate={field.disabledDate ?? undefined}
						/>
					</Form.Item>
				);
			}
			case 'datetime': {
				return (
					<Form.Item 
						{...formProps}
					>
						<DatePicker 
							disabled={disabled} 
							disabledDate={field.disabledDate ?? undefined}
							showTime={{ format: 'HH:mm' }}
							// showTime
						/>
					</Form.Item>
				)
			}
			case "image": {
				return (
					<Form.Item {...formProps} valuePropName="fileList" getValueFromEvent={normFile}>
						<Upload onChange={e => handleImageChange(e, field)} listType="picture" beforeUpload={() => false} multiple={!!field.multiple} maxCount={field.multiple ? 10 : 1}>
							<Button icon={<UploadOutlined />}>Зураг хуулах</Button>
						</Upload>
					</Form.Item>
				);
			}
			case 'array': {
				if (field.arrayType === 'embedded') {
					const parent = {
						...formProps,
						name: undefined
					}
					return (
						<Form.Item {...parent} key={`${field.key}_P`}>
							<Card bodyStyle={{ padding: 5 }}>
								{
									state.nestedArrayTypeA?.[field?.key]?.map((fields, o) => {
										let nestedArrayRendered = false;
										return (
											<Card key={`${field.key}_${o}`} style={{ marginTop: 15 }}>
												{
													state.nestedArrayTypeA?.[field.key]?.length > 0 && (
														<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
															<Button
																danger
																icon={<CloseOutlined />}
																size='small'
																onClick={() => {
																	dispatch({
																		type: actions.removeNestedTypeA,
																		payload: {
																			key: field.key,
																			index: o,
																		}
																	})
																}}
															/>
														</div>
													)
												}
												<div>
													{
														fields?.map((innerField, iF, oAR) => {
															const key = `${field.key}_${o}_${iF}`;
	
															let embeddedCount = 0;
	
															for (const e of oAR) {
																if (e.instance === 'array' && e.arrayType === 'embedded') {
																	nestedArrayRendered = true;
																	embeddedCount++;
																}
															};
	
															return (
																<div key={key} >
																	{
																		innerField.instance === 'string'
																		?	<Input.TextArea
																				autoSize
																				placeholder={innerField.name}
																				value={innerField.value ?? ''}
																				onChange={e => {
																					if (e.currentTarget.value.length <= 50) {
																						dispatch({
																							type: actions.updateNestedTypeAValue,
																							payload: {
																								location: [field.key, o, iF],
																								value: e.currentTarget.value
																							}
																						})
																					};
																				}}
																			/>
																		:	innerField.instance === 'array' && innerField.arrayType === 'embedded'
																			?	
																				<>
																					<Card style={{ marginTop: 15 }}>
																						{
																							embeddedCount > 1 &&
																							<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
																								<Button
																									danger
																									shape="circle"
																									icon={<CloseOutlined />}
																									size='small'
																									onClick={() => {
																										dispatch({
																											type: actions.removeNestedTypeAInnerArray,
																											payload: {
																												key: field.key,
																												index: iF,
																												parentIndex: o,
																											}
																										});
																									}}
																								/>
																							</div>
																						}
																						
																					{
																						innerField?.embedded?.map?.((f, lf) => {
																							if (f.key === 'icon') {
																								return (
																									<TetgelegIconSelect
																										key={`${field.key}_${o}_${iF}_${lf}`}
																										value={f.value}
																										onChange={value => {
																											dispatch({
																												type: actions.updateNestedTypeAValue,
																												payload: {
																													location: [field.key, o, iF, 'embedded',lf],
																													value,
																												}
																											})
																										}}
																									/>
																								);
																							};
																							return (
																								<Input.TextArea
																									autoSize
																									key={`${field.key}_${o}_${iF}_${lf}`}
																									placeholder={f.name}
																									value={f.value ?? ''}
																									onChange={e => {
																										const limit = f.key === 'info' ? 500 : 50;
																										if (e.currentTarget.value.length <= limit) {
																											dispatch({
																												type: actions.updateNestedTypeAValue,
																												payload: {
																													location: [field.key, o, iF, 'embedded',lf],
																													value: e.currentTarget.value
																												}
																											});
																										}
																									}}
																								/>
																							)
																						})												
																					}
																					</Card>
																				</>
																			: null
																		}
																		{
																			nestedArrayRendered && iF === oAR.length - 1
																			?	<Button
																					shape="circle"
																					icon={<PlusOutlined />} 
																					style={{ marginTop: 10, marginBottom: 10 }}
																					onClick={() => {
																						dispatch({
																							type: actions.addNestedTypeAInnerArray,
																							payload: {
																								key: field.key,
																								parentIndex: o,
																								field: innerField
																							}
																						})
																					}}
																				/>
																			:	null
																		}
																</div>
															)
														})
													}
												</div>
											</Card>
										);
										
									})
								}
								
							</Card>
							<Button 
								type="primary" 
								icon={<PlusOutlined />} 
								onClick={() => {
									dispatch({
										type: actions.addNestedTypeA,
										payload: {
											key: field.key,
											// fields: field?.value?.[0] ?? field.embedded,
											fields: field.embedded,
										}
									})
								}}
								style={{ marginTop: 10 }}
							/>
						</Form.Item>
					)
				};
				if (field.arrayType === 'question') {
					const parent = {
						...formProps,
						name: undefined
					};
					const nested = {
						...formProps,
						label: undefined,
					};
					return (
						<Form.Item {...parent} key={`${field.key}_P`}>
							<Form.Item {...nested}>
								<List
									size="small"
									bordered
									dataSource={state.tags?.[field.key]?.value}
									renderItem={(item) => <List.Item>{item}</List.Item>}
								/>
							</Form.Item>
						</Form.Item>
					)
				}
				const parent = {
					...formProps,
					name: undefined
				};
				const nested = {
					...formProps,
					label: undefined,
				};
				return (
					<Form.Item {...parent} key={`${field.key}_P`}>
						<Form.Item {...nested}>
							<Input.Group compact>
								<Input.TextArea
									allowClear
									autoSize
									style={{ width: 'calc(100% - 32px)', marginBottom: 10 }} // Note: 32px is icon width!
									onChange={e => handleTagInputChange(e.target.value, field?.key)}
									onPressEnter={e => {
										e.preventDefault();
										handleTagAdd(field.key)
									}}
									value={state.tags?.[field?.key]?.inputValue ?? ''}
								/>
								<Tooltip title="Нэмэх">
									<Button icon={<PlusOutlined onClick={() => handleTagAdd(field.key)} />} />
								</Tooltip>
							</Input.Group>
						</Form.Item>
						<div key={field.name + '1'}>
							{
								state.tags?.[field.key]?.value?.map?.((tag, index) => {
									return (
										<Tag key={`${field.key}_tag_${index}`} className='custom-form-tag' closable={true} onClose={e => handleTagClose(field.key, index, e)} >
											{tag}
										</Tag>
									)
								})
							}
						</div>
					</Form.Item>
				)
			}
			// case 'embedded': {
				
			// }
			case 'rte': {
				formProps.name = undefined;
				return (
					<Form.Item {...formProps}>
						<Button
							icon={<FormOutlined />}
							onClick={() => {
								dispatch({
									type: actions.richTextEditorChange,
									payload: {
										key: field.key,
										initialState: getRTEValue(field.key) ?? field.value,
										currentState: getRTEValue(field.key) ?? field.value,
									}
								})
							}}
						/>
						{
							state?.rte?.key && state.rte.key === field.key
							?	<Modal 
									title={field.name} 
									open 
									// onCancel={() => {
										// dispatch({
										// 	type: actions.richTextEditorDiscard,
										// 	payload: {
										// 		key: field.key
										// 	}
										// })
									// }}
									width='80%'
									onOk={() => {
										dispatch({
											type: actions.richTextEditorSave,
											payload: {
												key: field.key
											}
										})
									}}
									closeIcon={
										<CloseOutlined onClick={() => handleModalClose(field.key)}/>
									}
									cancelButtonProps={{ onClick: () => handleModalClose(field.key) }}
								>
									<Meditor
										onChange={data => {
											dispatch({
												type: actions.richTextEditorChange,
												payload: {
													key: field.key,
													currentState: data,
												}
											})
										}}
										initialValue={getRTEValue(field.key) ?? field.value}
										onImageUpload={handleEditorImageUpload}
									/>
								</Modal>
							:	null
						}
					</Form.Item>
				)
			}
			case 'textArea': {
				return (
					<Form.Item {...formProps}>
						<Input.TextArea disabled={disabled} autoSize/>
					</Form.Item>
				)
			}
			case "custom": {
				return <Form.Item {...formProps}>{field.component}</Form.Item>;
			}
			default:
				return null;
		}
	};

	const getFields = () => {
		const fieldItems = [];
		for (const [index, field] of fields.entries()) {
			if (field.show !== false) {
				fieldItems.push(getFormItem(field, index));
			}
		}
		return fieldItems;
	};

	const handleSubmit = async (fieldValues) => {
		const values = _.cloneDeep(fieldValues);
		const embeddedValues = getNew(state.nestedArrayTypeA);
		for (const field of fields) {
			const { key } = field;
			if (key) {
				if (field.instance === 'rte') {
					const disabled = field.editLock || field.editable === false;
	
					if (!disabled) {
						///TODO: maybe write here some formatting
						const rteState = state.rte.state;
						const object = rteState.find(obj => obj.key === key);
						let json = {};
						if (object) {
							json = object.currentState;
						} else {
							if (field.value) {
								if (typeof field.value === 'string') {
									json = JSON.parse(field.value)
								} else {
									json = field.value;
								}
							} else {
								json = {};
							}
						};
						const content = [];
						for (const subContent of (json?.content ?? [])) {
							if (subContent.type === 'image') {
								const data = [];
								for (const imageObject of subContent.attrs.data) {
									const file = imageObject.file;
									delete imageObject.file;
									let src = imageObject.src;
									if (file) {
										src = await imageUpload(file)
									}
									data.push({
										...imageObject,
										src
									});
								};
								
								content.push({
									...subContent,
									attrs: {
										...subContent.attrs,
										data,
									}
								});
							} else {
								content.push(subContent)
							};
						};
						json.content = content;
						values[key] = JSON.stringify(json);
					};
				};
				if (field.instance === 'array' && field.arrayType === 'embedded') {
					values[key] = embeddedValues?.[key] ?? undefined;
				};
				if (field.instance === 'image') {
					if (!values?.[key]?.[0].url) {
						const value = await imageUpload(values[key]);
						values[key] = value;
					} else {
						values[key] = values?.key?.[0]?.url;
					};
				};
			};
		};
		onSubmit(values);
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const handleTagInputChange = (value, key) => {
		if (key) {
			const tagsClone = _.cloneDeep(state.tags);
			tagsClone[key] = {
				...tagsClone[key],
				inputValue: value,
			};
			dispatch({
				type: actions.tagChange,
				payload: tagsClone
			});
		};
	};

	const handleTagAdd = key => {
		const tagsClone = _.cloneDeep(state.tags);
		const inputValue = tagsClone?.[key]?.inputValue ?? '';
		if (inputValue) {
			const value = tagsClone?.[key]?.value ?? [];
			value.push(inputValue);
			tagsClone[key] = {
				inputValue: '',
				value
			}
			dispatch({
				type: actions.tagChange,
				payload: tagsClone
			});
			form.setFieldsValue({
				[key]: value,
			});
		};
	};

	const handleTagClose = (key, index, event) => {
		event.preventDefault();
		const tagsClone = _.cloneDeep(state.tags);
		const value = tagsClone?.[key].value?.constructor === Array ? tagsClone?.[key].value : [];
		value.splice(index, 1);
		tagsClone[key].value = value;
		dispatch({
			type: actions.tagChange,
			payload: tagsClone
		});
		form.setFieldsValue({
			[key]: value,
		})
	};

	const handleFormValueChange = (changedValues) => {
		// const changedKeys = Object.keys(changedValues);
		// const secureKeys = fields.filter(field => field.secure).map(field => field.key);
		// const csKeys = [];
		// for (const changedKey of changedKeys) {
		//     if (secureKeys.includes(changedKey)) {
		//         csKeys.push(changedKey);
		//     }
		// }
		// if (csKeys.length) {
		//     const newChangedKeys = [];
		//     for (const key of csKeys) {
		//         if (!changedSecureKeys.includes(key)) {
		//             newChangedKeys.push(key);
		//         }
		//     }
		//     if (newChangedKeys.length) {
		//         setChangedSecureKeys([...changedSecureKeys, ...newChangedKeys]);
		//         const updatedValue = form.getFieldsValue();
		//         for (const key of newChangedKeys) {
		//             updatedValue[key] = changedValues[key];
		//         }
		//         form.setFieldsValue(updatedValue);
		//     }
		// }
	};

	const handleImageChange = (event, field) => {
		const { key } = field;
		const value = [];
		for (const fileObject of event.fileList) {
			const valid = validateImage(fileObject);
			if (valid) {
				value.push(fileObject);
			} else {
				message(`${fileObject.name} зургийн багтаамж зөвшөөрөгдсөн хэмжээнээс (5MB) хэтэрсэн байна!`, false);
			};
		};
		form.setFieldsValue({
			[key]: value,
		});
	};

	const getRTEValue = key => {
		const rteState = state.rte.state;
		const object = rteState.find(obj => obj.key === key);
		if (object) {
			if (object.currentState) {
				return object.currentState;
			}
			if (object.initialState) {
				return object.initialState;
			}
		};
		return null
	};

	const handleEditorImageUpload = files => {
		const images = [];
		for (const file of [...files]) {
			const valid = validateImage(file);
			if (valid) {
				images.push({
					src: URL.createObjectURL(file),
					file,
				})
			}
		};
		return images;
	};

	const handleModalClose = key => {
		dispatch({
			type: actions.richTextEditorDiscard,
			payload: {
				key,
			}
		});
	};

	return (
		<>
			<Form 
				form={form} 
				labelCol={{ span: 8 }} 
				wrapperCol={{ span: 8 }} 
				onFinish={handleSubmit} 
				onValuesChange={handleFormValueChange} 
				autoComplete="off" 
				className="custom-form"
			>
				{getFields()}
				<Row type="flex" justify="center" align="middle">
					<Button style={{ marginRight: 3 }} onClick={onCancel} type="link">
						Буцах
					</Button>
					<Button htmlType="submit" style={{ marginLeft: 3 }} type="primary">
						Хадгалах
					</Button>
				</Row>
			</Form>
		</>
	);
};

export default CustomForm;