import * as React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Space, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_access_items';

const AccessItems = () => {

	const [ data, setData ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

	const confirm = Modal.confirm;

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize
        });
  	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios
			.post(
				"/api/accessitem/list",
				params,
			)
			.then(({ data }) => {
				const { 
					list, 
					columns: cols,
					rest: { totalDocs = 1, limit = 20 } = {},  
				} = getFormattedResponse(data);
				setColumns(cols);
				setData(list);
				setTableState((prevState) => {
					return {
						...prevState,
						totalSize: totalDocs,
						loading: false,
						pageSize: limit,
					}
				});
			})
			.catch((e) => {
				console.log(e);
			});
  	};

  	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
		const params = {
			page,
            pageSize: pageSize,
            query: search,
            sort,
            order
		};
		setTableState({
			...tableState,
			page,
            pageSize,
            loading: true,
            sort,
            order
		})
		fetchData(params);
	};

	const deleteAccessItem = (id) => {
		return new Promise((resolve, reject) => {
			axios.delete(`/api/accessitem/${id}`)
			.then(({ data }) => {
				if (data.statusCode === 200) {
					fetchData(tableState);
				}
				resolve();
			})
			.catch(e => {
				reject();
				console.log(e);
			})
		})
	}

	const showConfirm = (_id) => {
		confirm({
			title: "Мэдээллийг устгах уу?",
			okText: "Устгах",
			cancelText: "Цуцлах",
			okType: 'danger',
			onOk() {
				deleteAccessItem(_id);
			},
		});
	};

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (col.key === "code") {
                render = (text, record) => {
                    return (
                        <Link
                            to={{
                                pathname: "/accessitem/edit",
                                state: {
                                    id: record._id,
                                },
                            }}
                        >
                            {text}
                        </Link>
                    );
                }
            }
            return {
                ...col,
                render,
            }
		})

		const additionalColumns = [
			{
				key: "action",
				width: 1,
				sorter: false,
				fixed: 'right',
				render: (text, record) => (
					<Space size="middle" align="center">
						<Link 
							to={{
								pathname: '/accessitem/edit',
								state: {
									id: record._id
								},
							}}
						>
							<EditOutlined />
						</Link>
						<DeleteOutlined onClick={() => showConfirm(record._id)} />
					</Space>
				),
			},
		];

		return [
			...formattedColumns,
			...additionalColumns
		];
	};

	const getSubheaderContent = () => {
		return (
			<Link to={`/accessitem/add`}>
				<Button type='primary'>
					Шинэ хандах элемент үүсгэх
				</Button>
			</Link>
		)
	}

	return (
		<PageContent
			subheader={{
				title: 'Хандах элментүүд',
				background: '#ffffff',
				content: getSubheaderContent(),
			}}
		>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				showFilter={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
	  	</PageContent>
	)
};

export default AccessItems;