import * as React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Steps } from 'antd';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import { handleFormImageUpload } from '../../utils';
import ScholarshipDetail from './Detail';

const AddScholarship = () => {
	const [ fields, setFields ] = React.useState([]);
	const [ current, setCurrent ] = React.useState(0);
	const [ scholarshipId, setScholarshipId ] = React.useState(null);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/scholarship`)
		.then(({ data = {} }) => {
			const { result = [] } = data;
			const formattedFields = (result ?? []).map(field => {
				if (field.key === 'title') {
					return {
						...field,
						customProps: {
							extra: '92 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
						}
					}
				}
				return {
					...field,
				}
			})

			setFields(formattedFields);
		})
		.catch(err => {
			console.log(err);
		})
	}, []);

	const handleSubmit = values => {
		axios.post(`/api/scholarship`, values)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				setScholarshipId(data.result._id);
				setCurrent(current + 1);
			}
		})
		.catch(err => {
			console.log(err);
		})
	};

	const handleCancel = () => {
		history.push('/scholarship')
	};

	const steps = [
		{
			title: 'Create',
			content: (
				<CustomForm
					fields={fields}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					imageUpload={handleFormImageUpload}
				/>
			),
		},
		{
			title: 'Detail',
			content: (
				<ScholarshipDetail
					scholarshipId={scholarshipId}
				/>
			),
		}
	];

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	return (
		<PageContent 
			background='#fff'
			subheader={{
				title: 'Tэтгэлэг үүсгэх',
				background: '#ffffff'
			}}
		>
			<Steps style={{ padding: '12px' }} current={current} onChange={e => { setCurrent(e) }} items={items} />
			<div style={{ marginTop: 20 }}>
				{steps[current].content}
			</div>
		</PageContent>
	)
}

export default AddScholarship;