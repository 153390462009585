import * as React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';

const AddTag = () => {

	const [ fields, setFields ] = React.useState([]);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/tag`)
		.then(({ data = {} }) => {
			const { result = [] } = data;
			setFields(result);
		})
		.catch(err => {
			console.log(err);
		})
	}, []);

	const handleSubmit = values => {
		axios.post(`/api/tag`, values)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/tag');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/tag')
	}

	return (
		<PageContent 
			background='#fff'
			subheader={{
				title: 'Tаг үүсгэх',
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={fields}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</PageContent>
	)
}

export default AddTag;