import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';

const EditAccessItem = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const [ fields, setFields ] = React.useState([]);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/accessitem/${id}`)
		.then(({ data = {} }) => {
			const { result = {  } } = data;
			setFields(result?.data ?? []);
		})
		.catch(err => {
			console.log(err);
		})
	}, [id]);

	const handleSubmit = (fields) => {
		axios.post(`/api/accessitem/${id}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/accessitem');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/accessitem')
	};

	return (
		<PageContent
			background='#ffffff'
			subheader={{
				title: 'Хандах эрх засах',
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={fields}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</PageContent>
	)
}

export default EditAccessItem;