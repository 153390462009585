import * as React from "react";
import { AiOutlineCamera } from 'react-icons/ai';
import { VscPlay } from 'react-icons/vsc';
import { CgBorderStyleDashed } from 'react-icons/cg';
import { GrBold } from "react-icons/gr";
import { GoItalic } from 'react-icons/go';
import { RiDoubleQuotesL } from "react-icons/ri";
import { BsLink45Deg } from "react-icons/bs";
import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from '@tiptap/extension-link';
import OutsideListener from "./OutsideListener";
import Iframe from './extensions/Iframe';
import "./styles.css";
import Separator from "./extensions/Separator";
import { ImageExtension } from "./extensions/ImageExtension";
import { getIframeSrc } from "./utils";
import { isJson } from "./utils";

const Meditor = ({
	editorOptions = {},
	onChange = () => undefined,
	onImageUpload = () => undefined,
	initialValue = '',
	style = {},
	output = 'json',
}) => {
	const [floatingMenuKey, setFloatingMenuKey] = React.useState(null);
	const editor = useEditor({
		extensions: [
			StarterKit,
			FloatingMenu,
			ImageExtension,
			Link.configure({
				openOnClick: true,
				autolink: false,
			}),
			Iframe,
			Separator
		],
		autofocus: 'end',
		content: isJson(initialValue) && typeof initialValue === 'string' ? JSON.parse(initialValue) : initialValue,
		injectCSS: true,
		onUpdate(props) {
			if (output === 'json') {
				const json = props.editor.getJSON();
				onChange?.(json);
			} else if (output === 'html') {
				const html  = props.editor.getHTML();
				onChange?.(html);
			}
			props.editor.commands.scrollIntoView();
		},
		...editorOptions,
	});

	const setLink = React.useCallback(() => {

		if (editor.isActive('link')) {
			editor.chain().focus().extendMarkRange('link').unsetLink().run()
			return
		}

		const previousUrl = editor.getAttributes('link').href
		const url = window.prompt('URL', previousUrl)

		// cancelled
		if (url === null) {
			return
		}

		// empty
		if (url === '') {
			editor.chain().focus().extendMarkRange('link').unsetLink()
				.run()

			return
		}

		// update link
		editor.chain().focus().extendMarkRange('link').setLink({ href: url })
			.run()
	}, [editor]);

	const handleImageUpload = (event) => {
		if (event.target.files?.length) {
			const { files } = event.target;
			const images = onImageUpload(files);
			editor.chain().focus().setImage({ images }).run();
		}
		event.target.value = null;
	};

	// const showImageSearch = () => {
	// 	setFloatingMenuKey("imageSearch");
	// };

	const showVideoEmbed = () => {
		setFloatingMenuKey('video');
	}

	//Floating menu render function
	const renderFloatingMenu = () => {
		switch (floatingMenuKey) {
			case "menu": {
				return (
					<div>
						<div className={`circle-menu-wrapper`}>
							<div
								className={`circle ${floatingMenuKey ? `circle-right` : `circle-left`
									}`}
								onClick={() => {
									setFloatingMenuKey(floatingMenuKey ? null : "imageSearch");
								}}
							/>
						</div>

						<OutsideListener
							onClickOutside={() => {
								setFloatingMenuKey(null);
							}}
						>
							<div
								className={`floating-menu-container`}
								style={floatingMenuKey ? { visibility: 'visible' } : { visibility: 'hidden' }}
							>
								<label
									htmlFor="select-image"
									className={`${editor.isActive("heading", { level: 1 }) ? "is-active" : ""
										}`}
									style={{ marginRight: '0.5rem', cursor: 'pointer' }}
								>
									<div className="floating-menu-circle-button" >
										<AiOutlineCamera style={{
											fontSize: '1.25rem',
											lineHeight: '1.75rem',
											color: 'rgb(55 65 81)'
										}} />
									</div>
								</label>
								{/* <button
									onClick={showImageSearch}
									className={
										editor.isActive("heading", { level: 2 })
											? "is-active mr-1"
											: "mr-1"
									}
								>
									<div className="floating-menu-circle-button">
										<IoSearchOutline className="text-xl text-gray-700" />
									</div>

								</button> */}
								<label
									onClick={showVideoEmbed}
									className={
										editor.isActive("bulletList") ? "is-active" : ""
									}
									style={{ marginRight: '0.5rem' }}
								>
									<div className="floating-menu-circle-button">
										<VscPlay style={{
											fontSize: '1.125rem',
											lineHeight: '1.75rem',
											color: 'rgb(55 65 81)'
										}} />
									</div>
								</label>
								{/* <button className="mr-1">
									<div className="floating-menu-circle-button">
										<BsCode style={{
											fontSize: '1.125rem',
											lineHeight: '1.75rem',
											color: 'rgb(55 65 81)'
										}} />
									</div>
								</button> */}
								<label style={{ marginRight: '0.5rem' }} onClick={() => editor.chain().focus().setHorizontalRule({

								}).run()}>
									<div className="floating-menu-circle-button">
										<CgBorderStyleDashed style={{
											fontSize: '1.125rem',
											lineHeight: '1.75rem',
											color: 'rgb(55 65 81)'
										}} />
									</div>
								</label>
							</div>
						</OutsideListener>
					</div>
				);
			}
			case 'video': {
				return (
					<OutsideListener
						onClickOutside={() => {
							setFloatingMenuKey(null);
						}}
					>
						<input
							placeholder="Paste embed tag..."
							className="flaoting-menu-embed"
							onKeyDown={e => {
								if (e.key === 'Enter') {
									// TODO: check if iframe is valid.
									const src = getIframeSrc(e.target.value);
									editor.chain().focus().setIframe({ src }).run();
									e.target.value = '';
									setFloatingMenuKey(null);
								}
							}}
						/>
					</OutsideListener>
				)
			}
			default:
				return (
					<div>
						<div className='circle-menu-wrapper'>
							<div
								className={`circle ${floatingMenuKey ? `circle-right` : `circle-left`}`}
								onClick={() => {
									setFloatingMenuKey("menu");
								}}
							/>
						</div>
						<div
							className={`floating-menu-container`}
							style={floatingMenuKey ? { visibility: 'visible' } : { visibility: 'hidden' }}

						/>
					</div>
				);
		}
	};

	//Bubble menu -ны цэсүүдийг харуулж буй функц
	const bubbleMenu = () => {
		const isActive = 'is-selected'
		return <div className="bubble-menu-wrapper">
			<button
				onClick={() => {
					const { to } = editor.state.selection;
					editor.chain().focus().toggleBold().setTextSelection(to).unsetBold().run();
				}}
				className={`${editor.isActive('bold') ? isActive : 'is-unselected'}`}
				style={{ marginRight: '0.25rem', cursor: 'pointer', marginTop: '0.2rem' }}

			>
				<GrBold style={{
					fontSize: '1.5rem',

				}} />
			</button>
			<button
				onClick={() => editor.chain().focus().toggleItalic().run()}
				className={`${editor.isActive('italic') ? isActive : 'is-unselected'}`}
				style={{ marginRight: '0.5rem', cursor: 'pointer', marginTop: '0.4rem' }}

			>
				<GoItalic style={{
					fontSize: '1rem',
				}} />
			</button>
			<button
				onClick={setLink}
				className={`${editor.isActive('link') ? isActive : 'is-unselected'}`}
				style={{ marginRight: '0.5rem', cursor: 'pointer', marginTop: '0.4rem' }}

			>
				<BsLink45Deg style={{
					fontSize: '1.2rem',
				}} />
			</button>
			<div className="bubble-menu-vertical-divider">
			</div>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				className={`${editor.isActive("heading", { level: 1 }) ? isActive : 'is-unselected'} bubble-menu-big-title-button`}
				style={{ cursor: 'pointer', marginTop: '0.1rem', marginRight: '0.5rem' }}

			>
				T
			</button>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				className={`${editor.isActive("heading", { level: 2 }) ? isActive : 'is-unselected'} bubble-menu-small-title-button`}
				style={{ cursor: 'pointer', marginTop: '0.1rem', marginRight: '0.5rem' }}

			>
				T
			</button>
			<button
				onClick={() => editor.chain().focus().toggleBlockquote().run()}
				className={editor.isActive("blockquote") ? isActive : 'is-unselected'}
				style={{ cursor: 'pointer', marginTop: '0.4rem' }}

			>
				<RiDoubleQuotesL style={{ fontSize: '1.5rem', lineHeight: '2rem' }} />
			</button>
		</div>
	}

	return (
		<div className='m-editor-wrapper' style={style}> 
			{editor && (
				<FloatingMenu editor={editor}>{renderFloatingMenu()}</FloatingMenu>
			)}
			{editor && (
				<BubbleMenu
					className={`bubble-menu`}
					tippyOptions={{
						duration: 100,
					}}
					shouldShow={({ from, to, editor }) => {
						if (editor.isActive('image')) {
							return false;
						};
						if (from === to) {
							return false;
						}
						if (editor.isActive('iframe')) {
							return;
						}
						return true;
					}}
					editor={editor}>
					{bubbleMenu()}
				</BubbleMenu>
			)}
			<EditorContent editor={editor} />
			<input
				accept="image/*"
				type="file"
				id="select-image"
				style={{ display: 'none' }}
				multiple={true}
				onChange={handleImageUpload}
			/>
		</div>
	);
};

export default Meditor;
