import React from 'react';
import { PageHeader } from 'antd';
import './header.less';

const SubHeader = ({
    background = 'transparent',
    title = '',
    children
}) => {
    return (
        <div className='subheader-container' style={{ backgroundColor: background }}>
            <PageHeader
                title={title}
            />
            <div className='subheader-content'>
                {children}
            </div>
        </div>
    )
}

export default SubHeader;