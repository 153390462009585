const reqSvgs = require.context('../../assets/svg/countries', false, /\.svg$/);
const allSvgFilePaths = reqSvgs.keys();

export const enums = allSvgFilePaths.map(f => {
	return f.substr(5).slice(0, -4);
});

export const enumDictionary = {};

for (const path of enums) {
    enumDictionary[path] = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={reqSvgs(`./ic_${path}.svg`).default}
                style={{ marginRight: 5 }}
                alt=""
            />
            {path}
        </div>
    );
};