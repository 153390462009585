import * as actions from './action';
import _ from 'lodash'; 

export const initialState = {
    tags: {},
    nestedArrayTypeA: {},
    rte: {
        key: null,
        state: [],
    },
};

export const reducer = (state, action) => {
    switch(action.type) {
        case actions.tagChange: {
            return {
                ...state,
                tags: action.payload,
            }
        }
        case actions.addNestedTypeA: {
            const { fields, key } = action.payload;

            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            const existingArray = nestedArrayTypeA?.[key] ?? [];

            if (key) {
                nestedArrayTypeA[key] = [...existingArray, fields];
            };

            return {
                ...state,
                nestedArrayTypeA
            }
        }
        case actions.addNestedTypeAInnerArray: {
            const { field, key, parentIndex } = action.payload;

            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            if (key) {
                for (const q of field.embedded) {
                    delete q.value;
                }
                delete field.value;
                nestedArrayTypeA[key][parentIndex] = [...nestedArrayTypeA[key][parentIndex], field ];
            };

            return {
                ...state,
                nestedArrayTypeA,
            }

        }
        case actions.updateNestedTypeAValue: {
            const { location, value } = action.payload;

            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            _.set(nestedArrayTypeA, [...location, 'value'], value);

            return {
                ...state,
                nestedArrayTypeA,
            }
        };
        case actions.removeNestedTypeA: {
            const { index, key } = action.payload;
            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            nestedArrayTypeA[key].splice(index, 1);

            return {
                ...state,
                nestedArrayTypeA,
            };
        };
        case actions.removeNestedTypeAInnerArray: {
            const { key, index, parentIndex } = action.payload;

            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            nestedArrayTypeA[key][parentIndex].splice(index, 1);

            return {
                ...state,
                nestedArrayTypeA,
            }
        };
        case actions.richTextEditorChange: {

            const oldRTEState = _.cloneDeep(state.rte.state);
            const payload = _.cloneDeep(action.payload);

            const { key } = payload;

            const index = oldRTEState.findIndex(obj => obj.key === key);

            let rteState = oldRTEState;

            if (index > -1) {
                const updatedState = {
                    ...oldRTEState[index],
                    ...payload
                };
                rteState[index] = updatedState;
            } else {
                rteState = [ ...oldRTEState, payload ];
            };

            const rte = {
                key,
                state: rteState
            }
            
            return {
                ...state,
                rte,
            }
        }
        case actions.richTextEditorSave: {
            const { key } = action.payload;
            const rteState = _.cloneDeep(state.rte.state);
            const index = rteState.findIndex(obj => obj.key === key);
            if (index > -1) {
                rteState[index].initialState = rteState[index].currentState;
            };
            
            const rte = {
                key: null,
                state: rteState
            };

            return {
                ...state,
                rte,
            }

        }
        case actions.richTextEditorDiscard: {
            const { key } = action.payload;

            const rteState = _.cloneDeep(state.rte.state);
            const index = rteState.findIndex(obj => obj.key === key);
            if (index > -1) {
                rteState[index].currentState = rteState[index].initialState;
            };
            
            const rte = {
                key: null,
                state: rteState
            };

            return {
                ...state,
                rte,
            };
        };
        case actions.embeddedTypeValueUpdate: {
            const embeddedValues = _.cloneDeep(action.payload.embeddedValues);

            const nestedArrayTypeA = _.cloneDeep(state.nestedArrayTypeA);

            for (const embeddedValue of embeddedValues) {
                const { key, value } = embeddedValue;

                const updatedValue = value.map(v => {
                    const array = [];
                    for (const x of v) {
                        if (x.instance === 'array' && x.arrayType === 'embedded') {
                            if (x.value) {
                                for (const n of x.value) {
                                    const q = {
                                        ...x,
                                        embedded: n,
                                    };
                                    array.push(q)
                                }
                            }
                        } else {
                            array.push(x);
                        };
                    };
                    return array;
                });
                nestedArrayTypeA[key] = updatedValue;
            };
            return {
                ...state,
                nestedArrayTypeA
            };
        }
        default:
            return state;
    }
};