const getColSpan = (count, index, layout) => {
    if (layout === 'block' || count === 1) {
        return 6;
    };
    if (count === 2) {
        return 3;
    };
    if (layout === 'grid') {
        return 2;
    };
    if (count % 3 === 1) {
        if (count - 4 <= index) {
            return 3;
        };
        return 2;
    };
    if (count % 3 === 2) {
        if (count - 2 <= index) {
            return 3;
        };
        return 2;
    };
    return 2;
};

export const getImagePosition = (index, imageCount, layout) => {
    const position = {
        colStart: 1,
        rowStart: 1,
        colSpan: getColSpan(imageCount, index, layout),
        rowSpan: 1,
    };

    if (layout === 'block') {
        position.rowStart = index + 1;
        return position;
    };
    if (layout === 'grid') {
        position.rowSpan = 1;
        position.colStart = (index % 3) * position.colSpan + 1;
        position.rowStart = Math.ceil((index + 1) / 3);
        return position;
    };

    position.rowSpan = 1;

    if (imageCount % 3 === 1) {
        if (imageCount - 4 <= index) {
            if (index % 2 === 0) {
                position.colStart = 4;
            } else {
                position.colStart = 1;
            }
            const dif = 5 - (imageCount - index); // 5 - (7 - 3)
            position.rowStart = Math.ceil((imageCount - 4) / 3) + Math.ceil(dif / 2);
            return position;
        };
        position.colStart = (index % 3) * position.colSpan + 1;
        position.rowStart = Math.ceil((index + 1) / 3);
        return position;
        // working fine.
    };

    if (imageCount % 3 === 2) {
        if (imageCount - 2 <= index) {
            if (index % 2 === 0) {
                position.colStart = 4;
            } else {
                position.colStart = 1;
            };
            const dif = 3 - (imageCount - index);
            position.rowStart = Math.ceil((imageCount - 2) / 3) + Math.ceil(dif / 2);
            return position;
        };
        position.colStart = (index % 3) * position.colSpan + 1;
        position.rowStart = Math.ceil((index + 1) / 3);
        return position;
        // working fine as well.
    }
    return position;
};

export const getIframeSrc = (string) => {
    if (typeof string === 'string') {
        const regExp = /src="([^"]*)/g;
        const array = [...string.matchAll(regExp)];
        return array[0][1];
    };
    return null;
};

export const isObject = value => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        return true;
    };
    return false;
};

export const getImageAttributes = (target, whitelist, blacklist, attrKey = 'data-', propKey = null) => {
    let attributes = {};
    
    if (isObject(target)) {
        for (const key in target) {
            if (target.hasOwnProperty(key)) {
                if (!blacklist.includes(key)) {
                    const k = whitelist.includes(key) ? key : `${attrKey}${key}`;
                    const subObject = getImageAttributes(target[key], whitelist, blacklist, `${attrKey}${k}-`, k);
                    attributes = {
                        ...attributes,
                        ...subObject,
                    };
                };
            };
        };
    } else {
        return {
            [propKey]: target,
        };
    };
    return attributes;
};

export const isJson = (item) => {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
};