import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import { handleFormImageUpload } from '../../utils';
import CustomForm from '../../components/form/CustomForm';
import CouponUsers from './User';

const CouponEdit = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const history = useHistory();
	const tabPanes = [
        {
            value: "coupon",
            title: "Сургалтын төвийн хөнгөлөлт"
        },
		{
            value: "users",
            title: "Xэрэглэгч"
        }
    ];

	const [ fields, setFields ] = React.useState([]);
	const [ tabValue, setTabValue ] = React.useState(tabPanes[0].value);

	React.useEffect(() => {
		axios.get(`/api/coupon/${id}`)
		.then(({ data = {} }) => {
			const { result = {  } } = data;
			const formattedFields = (result?.data ?? []).map(field => {
				if (field.key === 'title') {
					return {
						...field,
						customProps: {
							extra: '92 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
						}
					}
				}
				if (field.key === "image") {
					return {
						...field,
						value: [{
							url: field?.value || null,
						}]
					};
				}
				return {
					...field,
				}
			})
			setFields(formattedFields ?? []);
		})
		.catch(err => {
			console.log(err);
		})
	}, [id]);

	const handleSubmit = (fields) => {
		axios.post(`/api/coupon/${id}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/coupon');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/coupon')
	}

	return (
		<PageContent
			background='#ffffff'
			subheader={{
				title: 'Xөнгөлөлт засах',
				background: '#ffffff'
			}}
			type="tab"
            tabPanes={tabPanes}
            tabBackground="#ffffff"
            onTabChange={setTabValue}
		>
			{tabValue === "users" ? (
                <CouponUsers
					couponId={id}
				/>
            ) : (
				<CustomForm
					fields={fields}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					imageUpload={handleFormImageUpload}
				/>
			)}
		</PageContent>
	)
}

export default CouponEdit;