import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import './extensions.css';

const IframeComponent = (props) => {
    return (
        <NodeViewWrapper>
            <div className='iframe-container'>
                <iframe
                    width={560} height={340}
                    {...props.node.attrs}
                    title="..."
                />
                <div className='iframe'>
                    <input
                        spellCheck={false}
                        className='iframe-input'
                        placeholder='Caption here...'
                        value={props?.node?.attrs?.caption || ''}
                        onChange={e => {
                            props.updateAttributes({
                                caption: e.target.value,
                            })
                        }}
                    />
                </div>
            </div>
        </NodeViewWrapper>
    )
};

export default IframeComponent;