import HorizontalRule from '@tiptap/extension-horizontal-rule';
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import './extensions.css';

const Separator = HorizontalRule.extend({
    name: 'separator',
    addNodeView() {
        return ReactNodeViewRenderer(() => {
            return (
                <NodeViewWrapper>
                    <div className="seperator-wrapper">
                        <span style={{ marginRight: '0.75rem' }}>.</span>
                        <span style={{ marginRight: '0.75rem' }}>.</span>
                        <span>.</span>
                    </div>
                </NodeViewWrapper>
            )
        });
    },
});

export default Separator;