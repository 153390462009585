import * as React from "react";
import axios from "axios";
import moment from 'moment';
import { Button, Modal } from 'antd';
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomForm from '../../components/form/CustomForm';
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_coupon_user';

const CouponUsers = ({ couponId }) => {

	const [ modalData, setModalData ] = React.useState(null);
	const [ data, setData ] = React.useState([]);
	const [ filters, setFilters ] = React.useState();
	const [ columns, setColumns ] = React.useState([]);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            totalSize = 1,
			couponId: initialCouponId = couponId ?? null
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            totalSize,
			couponId: initialCouponId
        }
    });

  	React.useEffect(() => {
		const { page, pageSize, couponId } = tableState;
        fetchData({
            page,
            pageSize,
			couponId
        });
  	}, [tableState.couponId]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios.post(
			"/api/couponUser/list", {
				...params
			}
		)
		.then(({ data }) => {
			let users = data;
			if (users.result.meta.hasOwnProperty('organization')) {
				users.result.docs = users.result.docs.map(user => {
					return {
						...user,
						organization: user.organization?.nameMn
					};
				});
			}
			const { 
				list, 
				columns: cols,
				filter,
				rest: { totalDocs = 1, limit = 20 } = {}
			} = getFormattedResponse(users);
			setColumns(cols);
			setData(list);
			setFilters(filter);
			setTableState((prevState) => {
				return {
					...prevState,
					totalSize: totalDocs,
					pageSize: limit,
					couponId: couponId
				}
			});
		})
		.catch((e) => {
			console.log(e);
		});
	};

	const handleInteraction = ({ page, pageSize, couponId, search, sort, order }) => {
		const params = {
			page,
            pageSize: pageSize,
			couponId: couponId,
            query: search,
            sort,
            order
		};
		setTableState({
			...tableState,
			page,
            pageSize,
			couponId,
            sort,
            order
		})
		fetchData(params);
	};

	const getDetail = async id => {
		try {
			const { data = {} } = await axios.get(`/api/couponUser/${id}`);
			const { result = {} } = data;
			setModalData(result);
		} catch (error) {
			console.log('Fetch Order Detail Error: ', error.message);
		}
	}

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (col.instance === "boolean") {
                return {
                    ...col,
                    render(text) {
                        const status = text ? 'active' : 'inactive';
                        return (
                            <div className={`${status}-indicator`}/>
                        )
                    },
                }
            };
			if (['deadline', 'publishedDate', 'paidDate', 'checkedInDate'].includes(col.key)) {
                return {
                    ...col,
                    render(text) {
                        if (text) {
                            return moment(text).format('YYYY-MM-DD HH:mm')
                        };
                        return '-'
                    },
                }
            }
			if (['email'].includes(col.key)) {
				render = (text, record) => {
					return (
						<Button style={{ padding: 0 }} type="link" onClick={() => getDetail(record._id)}>
							{text}
						</Button>
					);
				}
			}
            return {
                ...col,
                render,
            }
		})

		return [
			...formattedColumns
		];
	};

	const handleSubmit = (modalData) => {
		axios.post(`/api/coupon/${modalData._id}`, modalData)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				setModalData(null);
				fetchData(tableState);
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		setModalData(null)
	};

	return (
		<>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
				filters={filters}
			/>
			<Modal
				title='Хэрэглэгчийн дэлгэрэнгүй'
				open={modalData !== null}
				onCancel={() => {
					setModalData(null)
				}}
				footer={null}
				width={800}
			>
				<CustomForm
					fields={modalData?.data}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
				/>
			</Modal>
		</>
	)
}

export default CouponUsers;
