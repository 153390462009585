import * as React from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];
export function useClickOutside(handler, events, nodes) {
    const ref = React.useRef();
    React.useEffect(() => {
        const listener = (event) => {
            if (Array.isArray(nodes)) {
                const shouldTrigger = nodes.every((node) => !!node && !node.contains(event.target));
                shouldTrigger && handler();
            }
            else if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        };
        (events || DEFAULT_EVENTS).forEach((fn) => document.addEventListener(fn, listener));
        return () => {
            (events || DEFAULT_EVENTS).forEach((fn) => document.removeEventListener(fn, listener));
        };
    }, [ref, handler, events, nodes]);
    return ref;
};

export default useClickOutside;