import * as React from "react";
import axios from "axios";
import { Select } from "antd";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_giftCode';

const GiftCodes = () => {

	const [ data, setData ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ status, setStatus ] = React.useState(null);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize,
			status: status
        });
  	}, [status]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = params => {
		setData([]);
		axios.post(
			"/api/gift-card-codes/list",
			params,
		)
		.then(({ data }) => {
			const { 
				list,
				columns: cols,
				rest: { totalDocs = 1, limit = 20 } = {},  
			} = getFormattedResponse(data);
			setColumns(cols);
			setData(list);
			setTableState((prevState) => {
				return {
					...prevState,
					totalSize: totalDocs,
					loading: false,
					pageSize: limit,
				}
			});
		})
		.catch((e) => {
			console.log(e);
		});
  	};

  	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            query: search,
            sort,
            order,
			status: status
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (col.key === "duration") {
				render = (text, record) => (
					`${text} cар`
				)
			}
            return {
                ...col,
                render
            }
		})

		return [
			...formattedColumns
		];
	};

	return (
		<PageContent
			subheader={{
				title: 'Бэлгийн картны код',
				background: '#ffffff'
			}}
		>
			<Select
				defaultValue="Төлөв"
				style={{
					width: 150,
					marginLeft: "328px",
					marginBottom: "-50px"
				}}
				onChange={e => { setStatus(e) }}
				options={[
					{
						value: '',
						label: 'Бүгд'
					},
					{
						value: 'active',
						label: 'Active'
					},
					{
						value: 'redeemed',
						label: 'Redeemed'
					}
				]}
			/>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
	  	</PageContent>
	)
};

export default GiftCodes;