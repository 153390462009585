import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CustomForm from '../../components/form/CustomForm';

const JobDetail = ({
	jobId = null,
}) => {

	const history = useHistory();

	const [ fields, setFields ] = React.useState([]);

	React.useEffect(() => {
        axios.get(`/api/viewlist/${jobId}`)
        .then(({ data = {} }) => {
            const { result = {} } = data;

			const customFields = result?.data?.map(field => {
				if (field.key === 'parentId') {
					return {
						...field,
						value: jobId,
						editable: false,
					}
				}

				return {
					...field,
					show: true,
				}
			});

            setFields(customFields);
        })
        .catch(err => {
            console.log(err);
        })
	}, [jobId]);

	const handleSubmit = (fields) => {
		axios.post(`/api/viewlist/${jobId}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/job');
			}
		})
		.catch(err => {
			console.log(err);
		});
	};

	const handleCancel = () => {
		history.push('/job')
	};

	return (
		<CustomForm
			fields={fields}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
		/>
	)
}

export default JobDetail;