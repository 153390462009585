import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./customtable.less";

const SearchInput = ({ inputProps = {}, onChange = () => undefined, value = "", onSearch = () => undefined }) => {
	const handleChange = (e) => {
		if (e.type === "click") {
			onSearch(e.target.value);
		}
		onChange(e.target.value);
	};

	const search = () => {
		onSearch(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Input
					className="search-input"
					value={value}
					onChange={handleChange}
					placeholder={"Хайх"}
					prefix={<SearchOutlined className="search-icon" />}
					// onKeyDown={handleKeyDown}
					// onBlur={search}
					onPressEnter={search}
					allowClear
					name="table-search"
					autoComplete="table-search"
					{...inputProps}
				/>
				<input type="submit" style={{ display: "none" }} />
			</form>
		</div>
	);
};

export default SearchInput;
