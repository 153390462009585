import * as React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import { Modal, Space, Button, message } from "antd";
import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = "config_scholarships";

const Scholarships = () => {
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [tableState, setTableState] = React.useState(() => {
        const { pageSize = 20, page = 1, loading = false, totalSize = 1 } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        };
    });

    const confirm = Modal.confirm;

    React.useEffect(() => {
        const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios
			.post(
				"/api/scholarship/list",
				params,
			)
			.then(({ data }) => {
				const { 
					list,
					columns: cols,
					rest: { totalDocs = 1, limit = 20 } = {},  
				} = getFormattedResponse(data);
				setColumns(cols);
				setData(list);
				setTableState((prevState) => {
					return {
						...prevState,
						totalSize: totalDocs,
						loading: false,
						pageSize: limit,
					}
				});
			})
			.catch((e) => {
				console.log(e);
			});
  	};

    const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            query: search,
            sort,
            order
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

    const deleteScholarship = (id) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/scholarship/${id}`)
                .then(({ data }) => {
                    if (data.statusCode === 200) {
                        fetchData(tableState);
                    }
                    resolve();
                })
                .catch((e) => {
                    reject();
                    console.log(e);
                });
        });
    };

    const showConfirm = (_id) => {
        confirm({
            title: "Мэдээллийг устгах уу?",
            okText: "Устгах",
            cancelText: "Цуцлах",
            okType: "danger",
            onOk() {
                deleteScholarship(_id);
            },
        });
    };

    const getColumns = () => {
        const formattedColumns = columns.map((col) => {
            let render;
            if (col.instance === "boolean") {
                return {
                    ...col,
                    render(text) {
                        const status = text ? 'active' : 'inactive';
                        return (
                            <div className={`${status}-indicator`}/>
                        )
                    },
                }
            };
            if (['deadline', 'publishedDate'].includes(col.key)) {
                return {
                    ...col,
                    render(text) {
                        if (text) {
                            return moment(text).format('YYYY-MM-DD HH:mm')
                        };
                        return '-'
                    },
                }
            }
            if (col.key === "title") {
                return {
                    ...col,
                    render(text, record)    {
                        return (
                            <Link
                                to={{
                                    pathname: "/scholarship/edit",
                                    state: {
                                        id: record._id,
                                    },
                                }}
                            >
                                {text}
                            </Link>
                        );
                    },
                }
            };
            return {
                ...col,
                render,
            };
            
        });

        const CopyButton = ({ id }) => {
            const textToCopy = `tetgeleg.mn/scholarship/${id}`;

            const handleCopy = () => {
                navigator.clipboard.writeText(textToCopy).then(() => {
                    message.success(`Text copied: ${textToCopy}`);
                }, () => {
                    message.error("Failed to copy");
                });
            };

            return <CopyOutlined type="copy" onClick={handleCopy} />;
        };

        const additionalColumns = [
            {
                key: "action",
                width: 1,
                sorter: false,
                fixed: "right",
                render: (text, record) => (
                    <Space size="middle" align="center">
                        <CopyButton id={record._id} />
                        <Link
                            to={{
                                pathname: "/scholarship/edit",
                                state: {
                                    id: record._id,
                                },
                            }}
                        >
                            <EditOutlined />
                        </Link>
                        <DeleteOutlined onClick={() => showConfirm(record._id)} />
                    </Space>
                ),
            },
        ];

        return [...formattedColumns, ...additionalColumns];
    };

	const getSubheaderContent = () => {
		return (
			<Link to={`/scholarship/add`}>
				<Button type='primary'>
					Шинэ тэтгэлэг үүсгэх
				</Button>
			</Link>
		)
	}

	return (
		<PageContent
			subheader={{
				title: 'Тэтгэлэг',
				background: '#ffffff',
				content: getSubheaderContent(),
			}}
		>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
	  	</PageContent>
	)
};

export default Scholarships;
