import React from 'react';
import { Select } from 'antd';

const reqSvgs = require.context('../../../assets/svg/icons', false, /\.svg$/);
const allSvgFilePaths = reqSvgs.keys();

const enums = allSvgFilePaths.map(f => {
	return f.substr(5).slice(0, -4);
});

export const enumDictionary = {};

const TetgelegIconSelect = ({
    value,
    onChange
}) => {
    return (
        <Select 
            value={value}
            showSearch
            filterOption={true}
            onChange={onChange}
        >
            {
                enums.map(option => {
                    return (
                        <Select.Option key={option} value={option} placeholder='Select Icon'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={reqSvgs(`./ic_${option}.svg`).default}
                                    style={{ marginRight: 5, width: 15, height: 15 }}
                                    alt=""
                                />
                                {option}
                            </div>
                        </Select.Option>
                    )
                })
            }
        </Select>
    )
};

export default TetgelegIconSelect;