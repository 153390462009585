import * as React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import axios from "axios";
import { ConfigProvider } from "antd";
import mnMN from 'antd/lib/locale/mn_MN';
import Auth from "./layouts/auth";
import Dashboard from "./layouts/dashboard";
import AppContext from "./context/AppContext";
import message from "./components/message";

let requestInterceptor = null;
let responseInterceptor = null;

const App = () => {
	const [loading, setLoading] = React.useState(false);

	if (!!requestInterceptor || requestInterceptor === 0) {
		axios.interceptors.request.eject(requestInterceptor);
	}

	if (!!responseInterceptor || responseInterceptor === 0) {
		axios.interceptors.response.eject(responseInterceptor);
	};

	const logout = () => {
		localStorage.clear();
		window.location.href = '/';
	};

	requestInterceptor = axios.interceptors.request.use((config) => {
		const BASE_URL = process.env.REACT_APP_BASE_URL;
		config.headers = {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("token"),
			...config.headers
		};

		if (!config.url.startsWith('http')) {
			config.url = `${BASE_URL}${config.url}`;
		}
		setLoading(true);
		return config;
	});

	responseInterceptor = axios.interceptors.response.use(
		(res) => {
			if (res?.data?.statusCode === 400) {
				message(res.data.message_mn ?? 'Алдаа гарлаа');
			}
			setLoading(false);
			return res;
		},
		(error) => {
			if (error.response.status === 401) {
				logout();
			} else {
				if (error.response.data.show !== false) {
					if (error.response.data.message_mn) {
						message(error.response.data.message_mn);
					} else if (error.response.data.message_en) {
						message(error.response.data.message_en);
					} else {
						message('Алдаа гарлаа');
					}
				}
			}
			setLoading(false);
    		return Promise.reject(error);
		}
	);

	if (!localStorage.getItem("token")) {
		return (
			<BrowserRouter>
				<Auth />
			</BrowserRouter>
		);
	}

	return (
		<ConfigProvider locale={mnMN}>
			<AppContext value={loading}>
				<BrowserRouter>
					<Switch>
						<Dashboard loading={loading} />
					</Switch>
				</BrowserRouter>
			</AppContext>
		</ConfigProvider>
	);
};

export default App;
