import axios from 'axios';

export const getFormattedResponse = (data) => {
	if (!data) {
		return {
			list: [],
			columns: [],
			rest: {},
			raw: data,
			filter: [],
		};
	}
	const { result = {} } = data;
	const { docs = [], meta = {}, filter, ...rest } = result;
	const list = docs.map((item) => {
		const itemObject = {};
		for (const key in item) {
			let value = item.hasOwnProperty(key) ? item[key] : null;
			if (value && meta?.[key]?.enum_dictionary?.hasOwnProperty(value)) {
				value = meta[key].enum_dictionary[value];
			} else if (value && meta?.[key]?.enumDictionary?.hasOwnProperty(value)) {
				value = meta[key].enumDictionary[value];
			}
			itemObject[key] = value;
		}
		return itemObject;
	});
	const columns = [];
	for (const key in meta) {
		if (meta.hasOwnProperty(key)) {
			columns.push({
				...meta[key],
				dataIndex: key,
				title: meta[key]?.name ?? "-",
			});
		}
	}
	return {
		list,
		columns,
		rest,
		raw: data,
		filter,
	};
};

export const isNumberInput = (value) => {
    const re = /^[0-9\b]+$/;
    return value === '' || re.test(value);
}

export const capitalize = string => {
    if (typeof string === 'string' && string.length) {
        return `${string[0].toUpperCase()}${string.substring(1).toLowerCase()}`;
    } else {
        return string;
    }
};

export const uploadFile = async (file) => {
	const formData = new FormData();
	const image = file.originFileObj ?? file
	formData.append("file", image);
	const res = await axios({
		method: "post",
		url: `${process.env.REACT_APP_STORAGE_URL}/v2/upload?prefix=image&useFileName=false`,
		data: formData,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		}
	});
	const url = res?.data?.result?.[0]?.fileUrl

	// const mobileImageUrl = `${url}?size=h466`;

	// fetch(mobileImageUrl)
	// 	.then(response => response.blob())
		// .then(imageBlob => {
		// 	// Then create a local URL for that image and print it
		// 	const imageObjectURL = URL.createObjectURL(imageBlob);
		// 	console.log(imageObjectURL);
		// });

	return url;
};

export const handleFormImageUpload = (files) => {
	return new Promise(async resolve => {
		try {
			if (!files.length && files) {
				resolve(uploadFile(files));
			} else if (files.length > 1) {
				const urls = Promise.all(Array.from(files).map(async file => {
					return uploadFile(file);
				}));
				resolve(urls);
			} else {
				resolve(uploadFile(files[0]));
			}
		} catch (err) {
			resolve(undefined);
		}
	});
};

export const getFormattedNumber = (number, separator = ',') => {
	if (!number) {
	   return null;
	}
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}
