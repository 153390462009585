import * as React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomForm from '../../components/form/CustomForm';
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_event_detail';

const EventDetail = ({ eventId = null, hasCV = false }) => {

	const [ modalData, setModalData ] = React.useState(null);
	const [ data, setData ] = React.useState([]);
	const [ userId, setUserId ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            totalSize,
        }
    });

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize
        });
  	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios.post(
			"/api/application/list", {
				...params,
				postId: eventId,
				postType: "event"
			}
		)
		.then(({ data }) => {
			const { 
				list, 
				columns: cols,
				rest: { totalDocs = 1, limit = 50 } = {},
			} = getFormattedResponse(data);
			setColumns(cols);
			setData(list);
			setTableState((prevState) => {
				return {
					...prevState,
					totalSize: totalDocs,
					pageSize: limit,
				}
			});
		})
		.catch((e) => {
			console.log(e);
		});
	};

	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
		const params = {
			page,
            pageSize: pageSize,
            query: search,
            sort,
            order
		};
		setTableState({
			...tableState,
			page,
            pageSize,
            sort,
            order
		})
		fetchData(params);
	};

	const getDetail = async id => {
		try {
			const { data = {} } = await axios.get(`/api/application/${id}`);
			const { result = {} } = data;
			setUserId(id);
			setModalData(result);
		} catch (error) {
			console.log('Fetch Order Detail Error: ', error.message);
		}
	}

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (col.instance === "boolean") {
                return {
                    ...col,
                    render(text) {
                        const status = text ? 'active' : 'inactive';
                        return (
                            <div className={`${status}-indicator`}/>
                        )
                    },
                }
            };
			if (['name'].includes(col.key)) {
				render = (text, record) => {
					return (
						<Button style={{ padding: 0 }} type="link" onClick={() => getDetail(record._id)}>
							{text}
						</Button>
					);
				}
			}
			if (col.key === "cv") {
                render = (text, record) => {
                    return (
						hasCV === true ?
                        <Link
							to={{
								pathname: "/event/cv",
								state: {
									id: record.userId,
								},
							}}
						>
							<FileSearchOutlined style={{ fontSize: "20px", textAlign: "center", width: "100%" }} />
						</Link>
						:
						"-"
                    );
                }
            }
            return {
                ...col,
                render,
            }
		})

		return [
			...formattedColumns
		];
	};

	const handleSubmit = (modalData) => {
		axios.post(`/api/application/${userId}`, modalData)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				setModalData(null)
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		setModalData(null)
	};

	return (
		<>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
			<Modal
				title='Хэрэглэгчийн дэлгэрэнгүй'
				open={modalData !== null}
				onCancel={() => {
					setModalData(null)
				}}
				footer={null}
				width={800}
			>
				{modalData?.data?.map((userID) => {
					if (userID.key === "userId") {
						return (
							<Link
								to={{
									pathname: "/event/cv",
									state: {
										id: userID.value,
									},
								}}
								key={userID.value}
							>
								<Button type="primary">CV харах</Button>
							</Link>
						)
					}
					return null
				})}
				<CustomForm
					fields={modalData?.data}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
				/>
			</Modal>
		</>
	)
}

export default EventDetail;
