import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import ScholarshipDetail from './Detail';
import ScholarshipUsers from './Users';
import { handleFormImageUpload } from '../../utils';
import message from '../../components/message';

const EditScholarship = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const history = useHistory();
	const tabPanes = [
        {
            value: "scholarship",
            title: "Мэдээлэл",
        },
        {
            value: "detail",
            title: "Дэлгэрэнгүй",
        },
		{
            value: "users",
            title: "Оролцогчид",
        },
		{
            value: "send",
            title: "Cонордуулгa",
        }
    ];

	const [ fields, setFields ] = React.useState([]);
	const [ notifields, setNotiFields ] = React.useState([]);
	const [ cv, setCv ] = React.useState(false);
	const [ tabValue, setTabValue ] = React.useState(tabPanes[0].value);

	React.useEffect(() => {
		if (tabValue === "scholarship") {
			axios.get(`/api/scholarship/${id}`)
			.then(({ data = {} }) => {
				const { result = {} } = data;
				const formattedFields = (result?.data ?? []).map(field => {
					if (field.key === 'title') {
						return {
							...field,
							customProps: {
								extra: '92 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
							}
						}
					}
					if (['deadline', 'publishedDate'].includes(field.key)) {
						return {
							...field,
							// disabledDate: disableDateBeforeToday,
						}
					}
					if (field.key === "image") {
						return {
							...field,
							value: [{
								url: field?.value || null,
							}]
						};
					}
					if (field.key === 'hasCV') {
						setCv(field.value)
						return {
							...field
						}
					}
					return {
						...field
					}
				})

				setFields(formattedFields);
			})
			.catch(err => {
				console.log(err);
			})
		} else if (tabValue === "send") {
			axios.get(`/api/contentNotif`)
			.then(({ data = {} }) => {
				const { result = { } } = data;
				const responseFields = (result ?? []).map((field) => {
					if (field.key === 'title') {
						return {
							...field,
							customProps: {
								extra: '20 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
							}
						}
					}
					return {
						...field
					};
				});
				setNotiFields(responseFields);
			})
			.catch(err => {
				console.log(err);
			})
		}
	}, [id, tabValue]);

	const handleSubmit = (fields) => {
		axios.post(`/api/scholarship/${id}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/scholarship');
			}
		})
		.catch(err => {
			message(err?.response?.data?.message_en, false)
			console.log(err);
		})
	}

	const handleNotifSubmit = (notifields) => {
		const params = {
			postId: id,
			postType: "scholarship",
			...notifields
		}
		axios.post(`/api/contentNotif`, params)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				message('Амжилттай', true);
			}
		})
		.catch(err => {
			console.log(err);
			message('Error', false);
		})
	}

	const handleCancel = () => {
		history.push('/scholarship')
	};
	
	return (
		<PageContent
			background='#fff'
			subheader={{
				title: 'Tэтгэлэг засах',
				background: '#ffffff'
			}}
			type="tab"
            tabPanes={tabPanes}
            tabBackground="#ffffff"
            onTabChange={setTabValue}
		>
			{tabValue === "detail" ? (
                <ScholarshipDetail
					scholarshipId={id}
				/>
            ) : tabValue === "users" ? (
				<ScholarshipUsers
					scholarshipId={id}
					hasCV={cv}
				/>
			) : tabValue === "send" ? (
				<CustomForm
					fields={notifields}
					onSubmit={handleNotifSubmit}
					onCancel={handleCancel}
				/>
			) : (
				<CustomForm
					fields={fields}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					imageUpload={handleFormImageUpload}
				/>
			)}
		</PageContent>
	)
}

export default EditScholarship;