import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { SideMenu } from "../../components/menu";
import dashboardRoutes from "../../routes/DashboardRoutes";
import appConfig from "../../appConfig";
import { Header } from "../../components/header";
import { NotFound } from "../../components/common";
import Loading from "../../components/layout/Loading";

const { Sider, Content } = Layout;

const DashboardLayout = ({ loading = false }) => {
    const [menu, setMenu] = React.useState("");

    React.useEffect(() => {
        const menuList = JSON.parse(localStorage.getItem("menu"));
        setMenu(menuList?.[0] ?? []);
    }, []);

    const index = () => {
        return <Redirect to={"/dashboard"} />;
    };

    const width = appConfig?.layout?.sideMenu?.width ?? undefined;

    return (
        <Layout className="layout">
            {loading && <Loading />}
            <Header />
            <Layout className="site-layout">
                <Sider theme="light" collapsible width={width ? `${width}px` : "250px"} breakpoint="sm" collapsedWidth={50} className="side-menu-sider">
                    <SideMenu menu={menu} />
                </Sider>
                <Layout>
                    <Content
                        className="site-layout-background"
                        style={{
                            minHeight: 280,
                            boxSizing: "border-box",
                            overflow: "scroll"
                        }}
                    >
                        <Route exact key="index" path="/" component={index} />
                        <Route path='/notfound' component={NotFound} />
						{/* <Redirect from='*' to='/scholarship' /> */}
                        {dashboardRoutes.map((props) => {
                            return <Route exact key={props.code} path={props.path} component={props.component} />;
                        })}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default DashboardLayout;
