import React from 'react';
import SubHeader from '../header/SubHeader';
import { Tabs } from 'antd';
import './layout.less';

const PageContent = ({
    subheader = false,
    children,
    type = null,
    tabPanes = [],
    onTabChange = () => undefined,
    tabBackground = 'transparent',
    background = 'transparent'
}) => {

    return (
        <div className='page-container'>
            {
                typeof subheader === 'object' && (
                    <SubHeader
                        title={subheader.title ?? '-'}
                        background={subheader.background}
                    >
                        {subheader.content ?? null}
                    </SubHeader>
                )
            }
            {
                type === 'tab' && (
                    <Tabs
                        className='page-content-tab'
                        style={{ backgroundColor: tabBackground }}
                        onChange={onTabChange}
                        items={
                            tabPanes.map(tabPane => {
                                return {
                                    label: tabPane.title,
                                    key: tabPane.value
                                }
                            })
                        }
                    />
                )
            }
            <div className='page-content-container' style={{ backgroundColor: background }}>  
                {children}
            </div>
        </div>
    )
}

export default PageContent;