import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import JobUsers from './Users';
import { handleFormImageUpload } from '../../utils';
import JobDetail from './Detail';
import message from '../../components/message';

const EditJob = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const history = useHistory();
	const tabPanes = [
        {
            value: "event",
            title: "Эвент",
        },
		{
			value: "detail",
			title: "Дэлгэрэнгүй"
		},
        {
            value: "users",
            title: "Оролцогчид",
        },
		{
            value: "send",
            title: "Cонордуулгa",
        }
    ];

	const [ fields, setFields ] = React.useState([]);
	const [ notifields, setNotiFields ] = React.useState([]);
	const [ cv, setCv ] = React.useState(false);
	const [ tabValue, setTabValue ] = React.useState(tabPanes[0].value);

	React.useEffect(() => {
		if (tabValue === "event") {
			axios.get(`/api/job/${id}`)
			.then(({ data = {} }) => {
				const { result = {  } } = data;
				const responseFields = (result?.data ?? []).map((field) => {
					if (field.key === 'title') {
						return {
							...field,
							customProps: {
								extra: '92 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
							}
						}
					}
					if (field.key === "image") {
						return {
							...field,
							value: [{
								url: field?.value || null,
							}]
						};
					}
					if (field.key === "logo") {
						return {
							...field,
							instance: "image",
							key: "logo",
							name: "Ажилын logo (зургийн зам)",
							show: true,
							value: [{
								url: field?.value || null,
							}]
						};
					}
					if (field.key === 'hasCV') {
						setCv(field.value)
						return {
							...field
						}
					}
					return {
						...field,
					};
				});
				setFields(responseFields);
			})
			.catch(err => {
				console.log(err);
			})
		} else if(tabValue === "send") {
			axios.get(`/api/contentNotif`)
			.then(({ data = {} }) => {
				const { result = { } } = data;
				const responseFields = (result ?? []).map((field) => {
					if (field.key === 'title') {
						return {
							...field,
							customProps: {
								extra: '20 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
							}
						}
					}
					if (field.key === "image") {
						return {
							...field,
							value: [{
								url: field?.value || null,
							}]
						};
					}
					return {
						...field
					};
				});
				setNotiFields(responseFields);
			})
			.catch(err => {
				console.log(err);
			})
		}
	}, [id, tabValue]);

	const handleSubmit = (fields) => {
		axios.post(`/api/job/${id}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/job');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleNotifSubmit = (notifields) => {
		const params = {
			postId: id,
			postType: "job",
			...notifields
		}
		axios.post(`/api/contentNotif`, params)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				message('Амжилттай', true);
			}
		})
		.catch(err => {
			message('Error', false);
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/job')
	};

	return (
		<PageContent
			background='#ffffff'
			type="tab"
			tabPanes={tabPanes}
			tabBackground="#ffffff"
			subheader={{
				title: 'Ажлын мэдээлэл засах',
				background: '#ffffff'
			}}
			onTabChange={setTabValue}
		>
			{tabValue === "users" ? (
                <JobUsers
					jobId={id}
					hasCV={cv}
				/>
            ) : tabValue === "detail" ? (
				<JobDetail
					jobId={id}
				/>
			) : tabValue === "send" ? (
				<CustomForm
					fields={notifields}
					onSubmit={handleNotifSubmit}
					onCancel={handleCancel}
				/>
			) : (
				<CustomForm
					fields={fields}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					imageUpload={handleFormImageUpload}
				/>
			)}
		</PageContent>
	)
}

export default EditJob;