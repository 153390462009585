import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Button, List } from "antd";
import PageContent from "../../components/layout/PageContent";
import { TITLE, API_URL } from "./index";
import styles from './styles.module.less';

const AppVersionList = () => {
	const { pathname } = useLocation();

	const [ iosVersion, setIosVersion ] = React.useState([]);
	const [ androidVersion, setAndroidVersion ] = React.useState([]);

	React.useEffect(() => {
		void fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const { data = {} } = await axios.post(`${API_URL}/list`);
			const { result = [] } = data;
			result?.docs.map(osType => {
				if (osType.osType === 'ios') {
					setIosVersion(osType);
				}
				if (osType.osType === 'android') {
					setAndroidVersion(osType);
				}
				return null;
			})
		} catch (error) {
			console.log('Fetch User List Error: ', error.message);
		}
	};

	const getData = () => {
		return [
			{
				title: 'Android',
				versionNumber: androidVersion?.versionNumber ?? null,
				forceUpdate: androidVersion?.isForceUpdate
			},
			{
				title: 'IOS',
				versionNumber: iosVersion?.versionNumber ?? null,
				forceUpdate: iosVersion?.isForceUpdate
			}
		];
	}

	const getSubheaderContent = () => {
		return (
			<Link to={`${pathname}/edit`}>
				<Button type='primary'>
					{TITLE} солих
				</Button>
			</Link>
		)
	}

	return (
		<PageContent
			subheader={{
				title: TITLE,
				background: '#ffffff',
				content: getSubheaderContent(),
			}}
			background={'#fff'}
		>
			<div className={styles.container}>
				<List
					style={{ width: 300 }}
					bordered
					itemLayout="horizontal"
					dataSource={getData()}
					renderItem={item => (
						<List.Item>
							<List.Item.Meta
								title={item.title}
								description={
									`Version: ${item.versionNumber} ${item.forceUpdate === true ? 'Forced' : ''}`
									??
									'-'
								}
							/>
						</List.Item>
					)}
				/>
			</div>
		</PageContent>
	)
};

export default AppVersionList;
