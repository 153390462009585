import * as React from 'react';
import { FaEllipsisV, FaGripVertical, FaRegTrashAlt } from "react-icons/fa";
import { BsFillGridFill, BsFillGrid1X2Fill } from "react-icons/bs";
import { GoThreeBars } from "react-icons/go";
import { NodeViewWrapper } from '@tiptap/react'
import { useClickOutside } from '../utils/hooks';
import { useResizeObserver } from '../utils/hooks/useResizeObserver';
import { getImagePosition } from '../utils';
import Tag from '../Tag';
import './extensions.css';

const ImageComponent = (props) => {

    const [showLayoutMenu, setShowLayoutMenu] = React.useState(false);
    const [layoutControl, setLayoutControl] = React.useState(null);
    const [layoutMenu, setLayoutMenu] = React.useState(null);

    const [imageContainerRef] = useResizeObserver();
    const firstCell = React.useRef(null);

    useClickOutside(() => {
        setShowLayoutMenu(false);
    }, null, [layoutControl, layoutMenu]);

    const handleChangeCaption = (e) => {
        props.updateAttributes({
            caption: e.target.value,
        })
    };

    const handleLayoutClick = () => {
        if (!showLayoutMenu) {
            setShowLayoutMenu(true);
        }
    };

    const handleImageLayoutChange = layout => {
        const images = [...(props?.node?.attrs?.data ?? [])];
        const data = images.map((image, index, arr) => {
            const position = getImagePosition(index, arr.length, layout);
            return {
                ...image,
                position,
            };
        });
        props.updateAttributes({
            layout,
            data,
        });
        setShowLayoutMenu(false);
    };

    const removeTag = index => {
        const tags = [...(props?.node?.attrs?.tags ?? [])];
        tags.splice(index, 1);
        props.updateAttributes({
            tags
        });
    };

    const removeImage = index => {
        const images = [...(props?.node?.attrs?.data ?? [])];
        const layout = props?.node?.attrs?.layout ?? 'grid';
        images.splice(index, 1);
        let lastRow = 0;

        const imagesArray = images.map((image, index, arr) => {
            const position = getImagePosition(index, arr.length, layout);
            if (lastRow < position.colStart) {
                lastRow = position.colStart;
            };
            return {
                ...image,
                position,
                grid: {
                    row: lastRow,
                    col: 6,
                },
            };
        });
        props.updateAttributes({
            data: imagesArray,
        })
    };

    const getImageHeight = index => {
        const width = imageContainerRef?.current?.childNodes?.[index]?.getBoundingClientRect?.().width ?? 1;
        return width / 16 * 9;
    };

    return (
        <NodeViewWrapper className={props?.node?.attrs?.style}>
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div data-drag-handle style={{ cursor: 'grab' }}>
                        <FaGripVertical />
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        ref={setLayoutControl}
                        onClick={handleLayoutClick}
                    >
                        <FaEllipsisV />
                    </div>

                </div>
                {
                    showLayoutMenu && (
                        <div ref={setLayoutMenu} className={`image-menu`}>
                            <div className='image-menu-button' style={{ fontSize: '18px' }} onClick={() => {
                                handleImageLayoutChange('grid');
                            }}>
                                <BsFillGridFill className='image-menu-icon' style={{}} />
                            </div>
                            <div className='image-menu-button' style={{ marginLeft: '0.7px' }}
                                onClick={() => {
                                    handleImageLayoutChange('block');
                                }}
                            >
                                <GoThreeBars className='image-menu-icon' style={{ fontSize: '22px' }} />
                            </div>
                            <div className='image-menu-button'
                                onClick={() => {
                                    handleImageLayoutChange('masonry');
                                }}
                            >
                                <BsFillGrid1X2Fill className='image-menu-icon-rotate image-menu-icon' />
                            </div>
                        </div>

                    )
                }
            </div>
            <div className='image-grid-wrap' ref={imageContainerRef}>
                {
                    (props?.node?.attrs?.data ?? [])?.map((imageData, index, array) => {
                        const colSpan = imageData?.position?.colSpan ?? 1;
                        const ref = index === 0 ? firstCell : undefined;
                        return (
                            <div
                                key={index}
                                ref={ref}
                                className={`grid-span-col-${colSpan} image-grid image-wrapper image-hover`}
                                style={{ height: getImageHeight(index) }}
                            >
                                <img
                                    src={imageData.src}
                                    alt={imageData.alt}

                                />
                                {
                                    props.node.attrs.data.length > 1 && <button
                                        className='image-remove-button'
                                        onClick={() => removeImage(index)}
                                    >
                                        <FaRegTrashAlt />
                                    </button>
                                }
                            </div>
                        )
                    })
                }
            </div>

            <div className='image-caption-input-wrapper'>
                <input
                    spellCheck={false}
                    className='image-caption-input'
                    placeholder='Caption here...'
                    value={props?.node?.attrs?.caption || ''}
                    onChange={handleChangeCaption}
                />
            </div>

            <div className='image-tag-container'>
                {
                    (props?.node?.attrs?.tags ?? []).map((tag, i) => {
                        return (
                            <Tag
                                key={i}
                                label={tag}
                                onClose={() => removeTag(i)}
                            />
                        )
                    })
                }
                <input
                    className='image-tag-input'
                    placeholder='+ Add tag'
                    onKeyDown={e => {
                        const { value } = e.target;
                        if (e.key === 'Enter' && e.target.value) {
                            const exist = (props?.node?.attrs?.tags ?? []).includes(value);

                            if (!exist) {
                                props.updateAttributes({
                                    tags: [...(props?.node?.attrs?.tags ?? []), e.target.value]
                                });
                                e.target.value = '';
                            }
                        };
                    }}
                />
            </div>
        </NodeViewWrapper >
    )
}

export default ImageComponent;