import React from 'react';
import { IoIosClose } from "react-icons/io";

const Tag = ({
    label,
    onClose
}) => {
    return (
        <div className='tag-container'>
            <span>
                {label ?? '-'}
            </span>
            <span className='tag-x-button' onClick={onClose}>
                <IoIosClose
                    style={{ fontSize: '1.5rem', lineHeight: '2rem' }}
                />
            </span>
        </div>
    )
};

export default Tag;