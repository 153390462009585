import appConfig from "../appConfig"

export const saveTableState = (tablePrefix, key, value) => {
    if (appConfig.prefix && tablePrefix && key) {
        localStorage.setItem(`${appConfig.prefix}_${tablePrefix}_${key}`, value);
    }
}

export const getTableState = tablePrefix => {
    if (appConfig.prefix && tablePrefix) {
        const state = {};
        for (const key in localStorage) {
            if (key.startsWith(`${appConfig.prefix}_${tablePrefix}`)) {
                const split = key.split('_');
                const stateKey = split?.[split.length - 1] ?? null;
                let value = localStorage.getItem(key);
                if (value) {
                    if (!isNaN(value)) {
                        value = Number(value);
                    }
                    state[stateKey] = value;
                }
            }
        }
        return state;
    }
    return {};
}

export const getTableStateByKey = (tablePrefix, key) => {
    return localStorage.getItem(`${appConfig.prefix}_${tablePrefix}_${key}`);
}

export const saveState = (key, value) => {
    localStorage.setItem(`${appConfig.prefix}_${key}`, value);
}

export const loadState = key => {
    return localStorage.getItem(`${appConfig.prefix}_${key}`);
}