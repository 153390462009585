import * as React from "react";
import axios from "axios";
import { Modal, Space, DatePicker, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";
import message from "../../components/message";

const tablePrefix = 'config_receipts';
const { RangePicker } = DatePicker;

const Receipts = () => {

	const [ data, setData ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ time, setTime ] = React.useState("");
	// const [ filters, setFilters ] = React.useState();
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 10,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

	const confirm = Modal.confirm;

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize,
			startDate: time?.[0],
			endDate: time?.[1]
        });
  	}, [ time ]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios
			.post(
				"/api/receipt/list",
				params,
			)
			.then(({ data }) => {
				const { 
					list, 
					columns: cols,
					// filter,
					rest: { totalDocs = 1, limit = 10 } = {},
				} = getFormattedResponse(data);
				setColumns(cols);
				setData(list);
				// setFilters(filter);
				setTableState((prevState) => {
					return {
						...prevState,
						totalSize: totalDocs,
						loading: false,
						pageSize: limit,
					}
				});
			})
			.catch((e) => {
				console.log(e);
			});
  	};

  	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            query: search,
            sort,
            order,
			startDate: time?.[0],
			endDate: time?.[1]
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

	const sendReceipt = (id) => {
		return new Promise((resolve, reject) => {
			axios.post(`/api/receipt/send`, {
				receiptId: id
			})
			.then(({ data }) => {
				if (data.statusCode === 200) {
					message(data.message ?? 'Амжилттай', true);
				}
				resolve();
			})
			.catch(e => {
				reject();
				message(data.message ?? 'Алдаа гарлаа', false);
				console.log(e);
			})
		})
	};

	const showConfirm = (_id) => {
		confirm({
			title: "Баримтын жагсаалт илгээх уу?",
			okText: "Илгээх",
			cancelText: "Цуцлах",
			okType: 'primary',
			onOk() {
				sendReceipt(_id);
			},
		});
	};

	const downloadCSVReport = async () => {
        axios.post('/api/receipt/export', {
			startDate: time?.[0],
			endDate: time?.[1]
		}, {
            responseType: 'blob',
        }).then((response) => {
            const fileName = response?.headers?.['x-file-name'] ?? `${Date.now()}.xls`;
            const url = window.URL.createObjectURL(new Blob([ '\ufeff', response.data ], { type: "text/tsv;charset=utf-8" }))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(err => console.log('Error>>>', err));
	};

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
            if (col.instance === 'boolean') {
                render = (text, record) => {
                    return record?.[col?.dataIndex] ? 'Тийм' : 'Үгүй';
                } 
            }
            return {
                ...col,
                render,
            }
		})

		const additionalColumns = [
			{
				key: "action",
				width: 1,
				sorter: false,
				fixed: 'right',
				render: (text, record) => (
					<Space size="middle" align="center">
						<SendOutlined onClick={() => showConfirm(record._id)} />
					</Space>
				),
			},
		];

		return [
			...formattedColumns,
			...additionalColumns
		];
	};

	return (
		<PageContent
			subheader={{
				title: 'Баримтын жагсаалт',
				background: '#ffffff',
				content: (
					<>
						<RangePicker
							onChange={e => { setTime(e) }}
						/>
						<Button
							onClick={downloadCSVReport}
							type='primary'
						>
							CSV татах
						</Button>
					</>
				)
			}}
		>
			
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				showFilter={false}
				onInteract={handleInteraction}
				state={tableState}
				// filters={filters}
			/>
	  	</PageContent>
	)
};

export default Receipts;