import { Dashboard } from "../views/dashboard";
import { Roles, AddRole, EditRole } from "../views/role";
import { Users, AddUser, EditUser } from "../views/user";
import { Permissions, EditPermission } from "../views/permission";
import { Scholarships, AddScholarship, EditScholarship, CvScholarship } from "../views/scholarship";
import { Contents, AddContent, EditContent } from "../views/content";
import { Schools, AddSchool, EditSchool } from "../views/school";
import { Organizations, AddOrganization, EditOrganization } from "../views/organization";
import { Tags, AddTag, EditTag } from "../views/tag";
import { Gifts, AddGift, EditGift } from "../views/gift-card";
import { GiftCodes } from "../views/gift-card-code";
import { Promos, AddPromo, EditPromo } from "../views/promo-code";
import { Partners, AddPartner, EditPartner } from "../views/partner-ship";
import { Coupons, AddCoupon, EditCoupon } from "../views/coupon";
import { Students } from "../views/student";
import { Notifications, AddNotification, EditNotification } from "../views/notification";
import { Events, AddEvent, EditEvent, CvEvent } from "../views/event";
import { Jobs, AddJob, EditJob, CvJob } from "../views/job";
import { Memberships } from "../views/membership";
import { Banners, AddBanner, EditBanner } from "../views/banner"
import { Countries, AddCountry, EditCountry } from "../views/country";
import { AccessItems, AddAccessItem, EditAccessItem } from "../views/accessitem";
import { AppVersionEdit, AppVersionList } from "../views/app-version";
import { Receipts } from "../views/receipt";

const dashboardRoutes = [
    { code: "dashboard", path: "/dashboard", name: "Хянах самбар", component: Dashboard },

    { code: "user", path: "/user", name: "Системийн хэрэглэгч", component: Users, parent: "settings" },
    { code: "useradd", path: "/user/add", name: "Системийн хэрэглэгч нэмэх", component: AddUser, parent: "user" },
    { code: "useredit", path: "/user/edit", name: "Системийн хэрэглэгч засах", component: EditUser, parent: "user" },

    { code: "receipt", path: "/receipt", name: "Баримтын жагсаалт", component: Receipts },

    { code: "role", path: "/role", name: "Роль", component: Roles, parent: "settings" },
    { code: "roleadd", path: "/role/add", name: "Роль нэмэх", component: AddRole, parent: "role" },
    { code: "roleedit", path: "/role/edit", name: "Роль засах", component: EditRole, parent: "role" },

    { code: "tag", path: "/tag", name: "Роль", component: Tags },
    { code: "tagadd", path: "/tag/add", name: "Роль нэмэх", component: AddTag, parent: "tag" },
    { code: "tagedit", path: "/tag/edit", name: "Роль засах", component: EditTag, parent: "tag" },

    { code: "permission", path: "/permission", name: "Өгөгдөлд хандах эрх", component: Permissions, parent: "settings" },
    { code: "permissionedit", path: "/permission/edit", name: "Өгөгдөлд хандах эрх засах", component: EditPermission, parent: "permission" },

    { code: "coupon", path: "/coupon", name: "Xөнгөлөлт", component: Coupons },
    { code: "couponadd", path: "/coupon/add", name: "Xөнгөлөлт нэмэх", component: AddCoupon, parent: "coupon" },
    { code: "couponedit", path: "/coupon/edit", name: "Xөнгөлөлт засах", component: EditCoupon, parent: "coupon" },

    { code: "promo", path: "/promo", name: "Промо", component: Promos },
    { code: "promoadd", path: "/promo/add", name: "Промо нэмэх", component: AddPromo, parent: "promo" },
    { code: "promoedit", path: "/promo/edit", name: "Промо засах", component: EditPromo, parent: "promo" },

    { code: "giftcardcode", path: "/giftcardcode", name: "Бэлгийн карт код", component: GiftCodes },

    { code: "giftcard", path: "/giftcard", name: "Бэлгийн карт", component: Gifts },
    { code: "giftcardadd", path: "/giftcard/add", name: "Бэлгийн карт нэмэх", component: AddGift, parent: "giftcard" },
    { code: "giftcardedit", path: "/giftcard/edit", name: "Бэлгийн карт засах", component: EditGift, parent: "giftcard" },

    { code: "scholarship", path: "/scholarship", name: "Тэтгэлэг", component: Scholarships },
    { code: "scholarshipcv", path: "/scholarship/cv", name: "Тэтгэлэг cv", component: CvScholarship, parent: "scholarship" },
    { code: "scholarshipadd", path: "/scholarship/add", name: "Тэтгэлэг нэмэх", component: AddScholarship, parent: "scholarship" },
    { code: "scholarshipedit", path: "/scholarship/edit", name: "Тэтгэлэг засах", component: EditScholarship, parent: "scholarship" },

    { code: "organization", path: "/organization", name: "Байгууллага", component: Organizations },
    { code: "organizationadd", path: "/organization/add", name: "Байгууллага нэмэх", component: AddOrganization, parent: "organization" },
    { code: "organizationedit", path: "/organization/edit", name: "Байгууллага засах", component: EditOrganization, parent: "organization" },

    { code: "content", path: "/content", name: "Mэдээлэл", component: Contents },
    { code: "contentadd", path: "/content/add", name: "Mэдээлэл нэмэх", component: AddContent, parent: "content" },
    { code: "contentedit", path: "/content/edit", name: "Mэдээлэл засах", component: EditContent, parent: "content" },

    { code: "partner", path: "/partner", name: "Хамтрагч", component: Partners },
    { code: "partneradd", path: "/partner/add", name: "Хамтрагч нэмэх", component: AddPartner, parent: "partner" },
    { code: "partneredit", path: "/partner/edit", name: "Хамтрагч засах", component: EditPartner, parent: "partner" },

    { code: "school", path: "/school", name: "Сургууль", component: Schools },
    { code: "schooladd", path: "/school/add", name: "Сургууль нэмэх", component: AddSchool, parent: "school" },
    { code: "schooledit", path: "/school/edit", name: "Сургууль засах", component: EditSchool, parent: "school" },

    { code: "student", path: "/student", name: "Сурагч", component: Students },

    { code: "notification", path: "/notification", name: "Сонордуулга", component: Notifications },
    { code: "notificationadd", path: "/notification/add", name: "Сонордуулга нэмэх", component: AddNotification, parent: "notification" },
    { code: "notificationedit", path: "/notification/edit", name: "Сонордуулга засах", component: EditNotification, parent: "notification" },

    { code: "event", path: "/event", name: "Эвент", component: Events },
    { code: "eventcv", path: "/event/cv", name: "Эвент cv", component: CvEvent, parent: "event" },
    { code: "eventadd", path: "/event/add", name: "Эвент нэмэх", component: AddEvent, parent: "event" },
    { code: "eventedit", path: "/event/edit", name: "Эвент засах", component: EditEvent, parent: "event" },
    
    { code: "job", path: "/job", name: "Ажил", component: Jobs },
    { code: "jobcv", path: "/job/cv", name: "Ажил cv", component: CvJob, parent: "job" },
    { code: "jobadd", path: "/job/add", name: "Ажил нэмэх", component: AddJob, parent: "job" },
    { code: "jobedit", path: "/job/edit", name: "Ажил засах", component: EditJob, parent: "job" },

    { code: "banner", path: "/banner", name: "Tуг", component: Banners },
    { code: "banneradd", path: "/banner/add", name: "Tуг нэмэх", component: AddBanner, parent: "banner" },
    { code: "banneredit", path: "/banner/edit", name: "Tуг засах", component: EditBanner, parent: "banner" },

    { code: "membership-renewals", path: "/membership-renewals", name: "Танд", component: Memberships },

    { code: "country", path: "/country", name: "Улс", component: Countries },
    { code: "countryadd", path: "/country/add", name: "Улс нэмэх", component: AddCountry, parent: "country" },
    { code: "countryedit", path: "/country/edit", name: "Улс засах", component: EditCountry, parent: "country" },

    { code: "accessitem", path: "/accessitem", name: "Хандах элементүүд", component: AccessItems, parent: "settings" },
    { code: "accessitemadd", path: "/accessitem/add", name: "Хандах элементүүд нэмэх", component: AddAccessItem, parent: "accessitem" },
    { code: "accessitemedit", path: "/accessitem/edit", name: "Хандах элементүүд засах", component: EditAccessItem, parent: "accessitem" },

    { code: "appversion", path: "/appversion", name: "App version", component: AppVersionList },
    { code: "appversionedit", path: "/appversion/edit", name: "App version өөрчлөх", component: AppVersionEdit, parent: "appversion" },
];

export default dashboardRoutes;
