import { Typography } from "antd";

const { Text } = Typography;

const Profile = () => {
	
	const logout = () => {
		localStorage.clear();
		window.location.href = "/";
	};

	return (
		<div className="profile">
			<Text onClick={logout} style={{ cursor: 'pointer' }}>Гарах</Text>
		</div>
	);
};

export default Profile;
