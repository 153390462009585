import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import IframeComponent from "./IframeComponent";

const Iframe = Node.create({
    name: 'videoEmbed',
    group: 'block',
    atom: true,
    addOptions() {
        return {
            allowFullScreen: true,
            HTMLAttributes: {
                class: 'iframe-wrapper',
            },
        }
    },

    addAttributes() {
        return {
            src: {
                default: null,
            },
            frameBorder: {
                default: 0,
            },
            allowFullScreen: {
                default: this.options.allowFullScreen,
            },
            caption: {
                default: '',
            },
        }
    },
    parseHTML() {
        return [{
            tag: 'iframe[src]',
        }]
    },
    addNodeView() {
        return ReactNodeViewRenderer(IframeComponent);
    },
    renderHTML({ HTMLAttributes }) {
        return ['iframe', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addCommands() {
        return {
            setIframe: (options) => ({ tr, dispatch, chain }) => {
                return chain().insertContent([
                    {
                        type: this.name,
                        attrs: {
                            ...options
                        }
                    },
                    {
                        type: 'paragraph'
                    }
                ]);
            },
        }
    },
});

export default Iframe;