import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';

const ScholarshipDetail = ({
	scholarshipId = null,
}) => {

	const history = useHistory();

	const [ fields, setFields ] = React.useState([]);

	React.useEffect(() => {
        axios.get(`/api/viewlist/${scholarshipId}`)
        .then(({ data = {} }) => {
            const { result = {} } = data;

			const customFields = result?.data?.map(field => {
				if (field.key === 'parentId') {
					return {
						...field,
						value: scholarshipId,
						editable: false,
					}
				}

				return {
					...field,
					show: true,
				}
			});

            setFields(customFields);
        })
        .catch(err => {
            console.log(err);
        })
	}, [scholarshipId]);

	const handleSubmit = (fields) => {
		axios.post(`/api/viewlist/${scholarshipId}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/scholarship');
			}
		})
		.catch(err => {
			console.log(err);
		});
	};

	const handleCancel = () => {
		history.push('/scholarship')
	};

	return (
		<CustomForm
			fields={fields}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
		/>
	)
}

export default ScholarshipDetail;