import * as React from 'react';
import axios from 'axios';
import { Button, Form, Input, Space, Typography } from 'antd';
import { KeyOutlined, MailOutlined, ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/img/logo.svg';
import message from '../../components/message';

const { Paragraph, Title } = Typography;

const Login = ({ onFinish, loginTypeChange }) => {
	const [ showOtp, setShowOtp ] = React.useState(false);
    const [ resetPass, setResetPass ] = React.useState(false);
	const [ loading, setLoading ] = React.useState(false);
	const [ token, setToken ] = React.useState(null);
    const [ code, setCode ] = React.useState(null);
	const [ email, setEmail ] = React.useState(null);

	const handleFinish = async values => {
		setEmail(values.email);
		try {
			setLoading(true);
			const { data } = await axios.post('/auth/tetgeleg/forgot-password', values);
			setLoading(false);
			if (data.statusCode === 200 && data.result.verificationToken) {
				message(data.result.message ?? 'Амжилттай', true);
				setToken(data.result.verificationToken);
				setShowOtp(true);
			}
		} catch (e) {
			setLoading(false);
			console.log('Forgot Submit Error: ', e.message);
		}
	};

    const handleOtp = async values => {
        setLoading(true);
        try {
            const params = {
                email: email,
                code: values.code,
                verificationToken: token
            };
            const { data } = await axios.post('/auth/tetgeleg/check-otp', params);
            setLoading(false);
            if (data.statusCode === 200) {
                message(data.result.message ?? 'Амжилттай', true);
                setCode(values.code);
                setShowOtp(false);
                setResetPass(true);
            }
        } catch (e) {
            setLoading(false);
            console.log('OTP error: ', e.message);
        }
    };

	const handleReset = async values => {
		setLoading(true);
		try {
			const { password } = values;
			const params = {
				email: email,
				password: password,
				code: code,
				verificationToken: token,
			};
			const { data } = await axios.post('/auth/tetgeleg/reset-password', params);
			if (data.resultCode === 200) {
                setResetPass(false);
                message(data.result.message ?? 'Амжилттай', true);
				onFinish(data.result);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log('Password Change Error: ', e);
		}
	};

	return (
		<div className='login-card'>
			<Space
				direction='vertical'
				size={0}
				className='login-space'
			>
				<img
					src={logo}
					className='login-logo'
					alt='Welcome'
				/>
				<Button
					icon={<ArrowLeftOutlined />}
					size={'large'}
					style={{ marginBottom: 80 }}
					onClick={() => loginTypeChange('login')}
				>
					Буцах
				</Button>
				<Paragraph>
					<Title
						level={2}
						style={{ marginTop: 0 }}
					>
						{showOtp ? 'Шинэ нууц үг оруулна уу' : 'И-мэйл хаяг оруулна уу'}
					</Title>
				</Paragraph>

				{showOtp ? (
					<Form
						name='normal_login'
						className='login-form'
						onFinish={handleOtp}
					>
						<Form.Item
							name='code'
							rules={[
								{
									required: true,
									message: 'Код оруулна уу!',
								},
							]}
						>
							<Input
								style={{ padding: '0.7rem 0.7rem' }}
								size='large'
								prefix={<LockOutlined className='site-form-item-icon' />}
								placeholder='OTP Код'
								allowClear
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								size='large'
								htmlType='submit'
								className='login-form-button'
								loading={loading}
							>
								Илгээх
							</Button>
						</Form.Item>
					</Form>
				) : resetPass ? (
                    <Form
						name='normal_login'
						className='login-form'
						onFinish={handleReset}
					>
						<Form.Item
							name='password'
							rules={[
								{
									required: true,
									message: 'Шинэ нууц үг оруулна уу!',
								},
							]}
						>
							<Input.Password
								style={{ padding: '0.7rem 0.7rem' }}
								size='large'
								prefix={<KeyOutlined className='site-form-item-icon' />}
								placeholder='Шинэ нууц үг'
								allowClear
							/>
						</Form.Item>
						<Form.Item
							name='confirm'
							rules={[
								{
									required: true,
									message: 'Шинэ нууц үгээ давтан оруулна уу!',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('Нууц үг тохирохгүй байна'));
									},
								}),
							]}
						>
							<Input.Password
								style={{ padding: '0.7rem 0.7rem' }}
								size='large'
								prefix={<KeyOutlined className='site-form-item-icon' />}
								placeholder='Шинэ нууц үг'
								allowClear
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								size='large'
								htmlType='submit'
								className='login-form-button'
								loading={loading}
							>
								Илгээх
							</Button>
						</Form.Item>
					</Form>
                ) : (
					<Form
						name='normal_login'
						className='login-form'
						onFinish={handleFinish}
					>
						<Form.Item
							name='email'
							rules={[
								{
									required: true,
									message: 'И-мэйл оруулна уу!',
								},
							]}
						>
							<Input
								style={{ padding: '0.7rem 0.7rem' }}
								size='large'
								prefix={<MailOutlined className='site-form-item-icon' />}
								placeholder='И-мэйл'
								allowClear
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								size='large'
								htmlType='submit'
								className='login-form-button'
								loading={loading}
							>
								Илгээх
							</Button>
						</Form.Item>
					</Form>
				)}
			</Space>
		</div>
	);
};

export default Login;
