import * as React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import { handleFormImageUpload } from '../../utils';

const EditOrganization = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const [ fields, setFields ] = React.useState([]);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/organization/${id}`)
		.then(({ data = {} }) => {
			const { result = {} } = data;
			const responseFields = (result?.data ?? []).map((field) => {
				if (["logo", "image"].includes(field.key)) {
					return {
						...field,
						value: [{
							url: field?.value || null,
						}]
					};
				}
				return {
					...field,
				};
			});
			setFields(responseFields);
		})
		.catch(err => {
			console.log(err);
		})
	}, [id]);

	const handleSubmit = (fields) => {
		axios.post(`/api/organization/${id}`, fields)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/organization');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/organization')
	};

	return (
		<PageContent
			background='#ffffff'
			subheader={{
				title: 'Байгууллагын мэдээлэл засах',
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={fields}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				imageUpload={handleFormImageUpload}
			/>
		</PageContent>
	)
}

export default EditOrganization;