import * as React from "react";
import {
	Layout,
	PageHeader,
	Card,
	Row,
	Col,
	Typography,
	DatePicker,
	Space,
	Select,
	Statistic
} from "antd";
import { UserOutlined, IdcardOutlined, AuditOutlined } from "@ant-design/icons";
import { Column, Area, BidirectionalBar, Pie } from "@ant-design/plots";
import axios from "axios";
import "./style.less";
import PageContent from "../../components/layout/PageContent";

const { Title, Text } = Typography;
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Dashboard = () => {
	const [overview, setOverview] = React.useState([]);
	const [contents, setContents] = React.useState([]);
	const [users, setUsers] = React.useState([]);
	const [renewals, setRenewals] = React.useState([]);
	const [member, setMember] = React.useState([]);
	const [interval, setInterval] = React.useState('month');
	const [time, setTime] = React.useState(['2023-01-01', Date()]);

	React.useEffect(() => {
		fetchData("/api/dashboard/published-contents");
		fetchData("/api/dashboard/registered-users");
		fetchData("/api/dashboard/membership-renewals");
		fetchData("/api/dashboard/active-memberships");
		fetchData("/api/dashboard/overview");
	}, [time]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (endpoint) => {
		axios.post(
		  endpoint, {
			startDate: time?.[0],
			endDate: time?.[1],
			interval: interval
		})
		.then(({ data: { result } }) => {
			if (endpoint === "/api/dashboard/published-contents") {
				setContents(result);
			} else if (endpoint === "/api/dashboard/registered-users") {
				setUsers(result);
			} else if (endpoint === "/api/dashboard/membership-renewals") {
				setRenewals(result);
			} else if (endpoint === "/api/dashboard/active-memberships") {
				setMember(result);
			} else if (endpoint === "/api/dashboard/overview") {
				setOverview(result);
			}
		})
		.catch((e) => {
		  	console.log(e);
		});
	};

	const mapData = data => {
		return data.map(obj => {
			return obj.data?.map(item => {
				return {
					date: item._id,
					value: item.value,
					type: obj.key,
					name: obj.label,
				};
			});
		}).flat();
	};

	const publishedData = {};

	for (const key in overview) {
		if (key.includes('published')) {
			const type = key.replace('published', '');
			publishedData[type] = overview[key];
		}
	}

	const formattedPublish = Object.entries(publishedData)?.map(([type, value]) => ({
		type,
		value
	}));

	const content = mapData(contents);
	const user = mapData(users);
	const members = mapData(member);
	const renewal = mapData(renewals);

	const renewalConfig = {
		data: renewal.map(item => {
			return {
				date: item.date,
				[item.name]: item.value
			}
		}),
		xField: 'date',
		xAxis: {
		  	position: 'bottom',
		},
		interactions: [
			{
				type: 'active-region',
			},
		],
		yField: ['6 сар', '12 сар'],
		label: {
			position: 'middle',
		},
		tooltip: {
			shared: true,
			showMarkers: false,
		},
	};

	const contentConfig = {
		data: content,
		isGroup: true,
		xField: 'date',
		yField: 'value',
		seriesField: 'name',
		xAxis: {
			label: {
				autoRotate: false
			},
		},
		label: {
			position: 'middle'
		}
	};

	const userConfig = {
		data: user,
		xField: 'date',
		yField: 'value',
		label: {
			position: 'middle',
		},
	};

	const memberConfig = {
		data: members,
		xField: 'date',
		yField: 'value',
		label: {
			position: 'middle',
		},
	};

	const publishConfig = {
		data: formattedPublish,
		angleField: 'value',
		colorField: 'type',
		radius: 1,
		label: {
			type: 'inner'
		}
	};

	const cardData = [
        {
            icon: UserOutlined,
            stats: overview?.registeredUsers,
            color: "#2FA1D9",
            title: "Нийт хэрэглэгч"
        },
        {
            icon: AuditOutlined,
            stats: overview?.membershipRenewals,
            color: "lime",
            title: "Танд эрх сунгалт"
        },
        {
            icon: IdcardOutlined,
            stats: overview?.activeMemberships,
            color: "orange",
            title: "Идэвхтэй гишүүнчлэл"
        }
    ];

  	return (
		<PageContent>
			<PageHeader
				className="site-page-header-responsive"
				backIcon={false}
				title={<Title level={3}>Хянах самбар</Title>}
				extra={[
					<Space size="large" key={Math.random()}>
						<Select defaultValue={interval} onChange={e => setInterval(e)}>
							<Option value="day">Day</Option>
							<Option value="month">Month</Option>
							<Option value="year">Year</Option>
						</Select>
						<RangePicker
							picker={interval}
							onChange={e => setTime(e)}
						/>
					</Space>
				]}
			>
				<Content>
					<Row justify='space-between'>
						<Col>
							<Space
								direction="vertical"
								size="small"
							>
								{cardData.map((el, index) => {
									return (
										<Card
											bordered={false}
											bodyStyle={{
												padding: "2rem 1.5rem",
												minWidth: "340px",
												maxWidth: "340px",
											}}
											className="card-container"
											key={index}
										>
											<Row justify="center" align="middle">
												<Col span={8}className="full-icon">
													<div
														className="icon-background"
														style={{ backgroundColor: el.color }}
													/>
													<el.icon
														className="icon-order"
														style={{ color: el.color }}
													/>
												</Col>
												<Col span={16}>
													<Text type="secondary">{el.title}</Text>
													<br />
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<Statistic value={el.stats} />
													</div>
												</Col>
											</Row>
										</Card>
									);
								})}
							</Space>
						</Col>
						<Col>
							<Pie {...publishConfig} />
						</Col>
					</Row>
					<br />
					<br />
					<Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]}>
						<Col md={24} lg={24} xl={24} xxl={12}>
							<Card
								title={<Title level={4}>Контент</Title>}
								bordered={false}
							>
								<Column {...contentConfig} />
							</Card>
						</Col>
						<Col md={24} lg={24} xl={24} xxl={12}>
							<Card
								title={<Title level={4}>Бүртгүүлсэн хэрэглэгчид</Title>}
								bordered={false}
							>
								<Area {...userConfig} />
							</Card>
						</Col>
						<Col md={24} lg={24} xl={24} xxl={12}>
							<Card
								title={<Title level={4}>Гишүүнчлэл</Title>}
								bordered={false}
							>
								<BidirectionalBar {...renewalConfig} />
							</Card>
						</Col>
						<Col md={24} lg={24} xl={24} xxl={12}>
							<Card
								title={<Title level={4}>Идэвхтэй танд хэрэглэгчид</Title>}
								bordered={false}
							>
								<Area {...memberConfig} />
							</Card>
						</Col>
					</Row>
				</Content>
			</PageHeader>
		</PageContent>
	);
};

export default Dashboard;
