import * as React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import produce from 'immer';
import { Button, Modal, List, Radio, Select, InputNumber } from 'antd';
import { ExclamationCircleOutlined, FileSearchOutlined } from '@ant-design/icons';
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomForm from '../../components/form/CustomForm';
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_scholarship_user';

const ScholarshipUsers = ({ scholarshipId = null, hasCV = false }) => {

	const [ modalData, setModalData ] = React.useState(null);
	const [ open, setOpen ] = React.useState(false);
	const [ questions, setQuestions ] = React.useState([]);
	const [ data, setData ] = React.useState([]);
	const [ userId, setUserId ] = React.useState([]);
	const [ progress, setProgress ] = React.useState("");
	const [ stage, setStage ] = React.useState(0);
	const [ updateprogress, setUpdateprogress ] = React.useState("");
	const [ updatestage, setUpdatestage ] = React.useState(0);
	const [ columns, setColumns ] = React.useState([]);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            totalSize,
        }
    });

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize,
			shortAnswer: questions?.length === 0 ? null : questions
        });
  	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios.post(
			"/api/application/list", {
				...params,
				postId: scholarshipId,
				postType: "scholarship"
			}
		)
		.then(({ data }) => {
			const {
				list, 
				columns: cols,
				filter,
				rest: { totalDocs = 1, limit = 50 } = {},
			} = getFormattedResponse(data);

			const _answers = filter?.shortQuestion?.map?.((question, index) => ({
				question,
				answer: null,
				index
			}));
			setQuestions(_answers);
			setColumns(cols);
			setData(list);
			setTableState((prevState) => {
				return {
					...prevState,
					totalSize: totalDocs,
					pageSize: limit,
				}
			});
		})
		.catch((e) => {
			console.log(e);
		});
	};

	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
		const params = {
			page,
            pageSize: pageSize,
            query: search,
            sort,
            order,
			shortAnswer: questions,
			progress: progress,
			stage: stage
		};
		setTableState({
			...tableState,
			page,
            pageSize,
            sort,
            order
		})
		fetchData(params);
	};

	const getDetail = async id => {
		try {
			const { data = {} } = await axios.get(`/api/application/${id}`);
			const { result = {} } = data;
			setUserId(id);
			setModalData(result);
		} catch (error) {
			console.log('Fetch Order Detail Error: ', error.message);
		}
	}

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (col.instance === "boolean") {
                return {
                    ...col,
                    render(text) {
                        const status = text ? 'active' : 'inactive';
                        return (
                            <div className={`${status}-indicator`}/>
                        )
                    },
                }
            };
			if (['name'].includes(col.key)) {
				render = (text, record) => {
					return (
						<Button style={{ padding: 0 }} type="link" onClick={() => getDetail(record._id)}>
							{text}
						</Button>
					);
				}
			}
			if (col.key === 'cv') {
				render = (text, record) => {
					return (
						hasCV === true ?
						<Link
							to={{
								pathname: "/scholarship/cv",
								state: {
									id: record.userId,
								},
							}}
						>
							<FileSearchOutlined style={{ fontSize: "20px", textAlign: "center", width: "100%" }} />
						</Link>
						:
						"-"
					);
				}
			}
            return {
                ...col,
                render,
            }
		})

		return [
			...formattedColumns
		];
	};

	const handleUpdate = () => {
		axios.post(`/api/application/updateMass`, {
			applications: JSON.parse(localStorage.getItem('selectedRow')),
			stage: updatestage,
			progress: updateprogress
		})
		.then(({ data }) => {
			if (data.statusCode === 200) {
				fetchData(tableState);
			}
		})
		.catch(err => {
			console.log(err);
		})
	};

	const finishSubmit = () => {
		axios.post(`/api/application/finish`, {
			postId: scholarshipId,
			postType: "scholarship"
		})
		.then(({ data }) => {
			if (data.statusCode === 200) {
				fetchData(tableState);
			}
		})
		.catch(err => {
			console.log(err);
		})
	};

	const handleSubmit = (modalData) => {
		axios.post(`/api/application/${userId}`, modalData)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				setModalData(null);
				fetchData(tableState);
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		setModalData(null)
	};

	const onChange = (index, { target: { value } }) => {
		setQuestions(
			produce(draft => {
				draft[index].answer = value;
			})
		)
	};

	const confirm = () => {
		Modal.confirm({
			centered: true,
			title: 'Санамж',
			icon: <ExclamationCircleOutlined />,
			content: 'Та үйл ажиллагааг дуусгахдаа итгэлтэй байна уу?',
			okText: 'Тийм',
			cancelText: 'Үгүй',
			onOk: finishSubmit
		});
	};

	const handleOk = () => {
		handleInteraction(questions, progress, stage)
		setOpen(false)
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<Button style={{ width: "120px", marginLeft: "16px", marginTop: "16px" }} type="primary" onClick={() => setOpen(true)}>
					Filter
				</Button>
				<Button danger onClick={confirm} style={{ width: "120px", marginRight: '16px', marginTop: '16px' }}>Дуусгах</Button>
			</div>
			<div style={{ display: "flex", gap: "1rem", paddingTop: "10px", paddingRight: "16px", marginBottom: "-4rem", justifyContent: "right" }}>
				<div>
					{}
				</div>
				<div>
					<div>Төлөв</div>
					<Select
						defaultValue="Төлөв"
						style={{
							width: 150,
							marginRight: "10px"
						}}
						onChange={e => setUpdateprogress(e)}
						options={[
							{
								value: '',
								label: 'Төлөв',
							},
							{
								value: 'waiting',
								label: 'Tүр хүлээнэ үү',
							},
							{
								value: 'accepted',
								label: 'Tэнцсэн',
							},
							{
								value: 'notAccepted',
								label: 'Tэнцээгүй',
							},
							{
								value: 'worked',
								label: 'Aжилласан',
							}
						]}
					/>
				</div>
				<div>
					<div>Үе шат</div>
					<InputNumber min={0} max={6} defaultValue={0} onChange={e => setUpdatestage(e)} />
				</div>
				<Button onClick={handleUpdate} style={{ width: "120px", marginTop: "auto" }}>Update</Button>
			</div>
			<Modal
				title="Q&A"
				open={open}
				width={800}
				onOk={handleOk}
				onCancel={() => setOpen(false)}
			>
				<div style={{ display: "flex", gap: "1rem" }}>
					<div>
						<div>Төлөв</div>
						<Select
							defaultValue="Төлөв"
							style={{
								width: 150,
								marginRight: "10px"
							}}
							onChange={e => setProgress(e)}
							options={[
								{
									value: '',
									label: 'Төлөв',
								},
								{
									value: 'waiting',
									label: 'Tүр хүлээнэ үү',
								},
								{
									value: 'accepted',
									label: 'Tэнцсэн',
								},
								{
									value: 'notAccepted',
									label: 'Tэнцээгүй',
								},
								{
									value: 'worked',
									label: 'Aжилласан',
								}
							]}
						/>
					</div>
					<div>
						<div>Үе шат</div>
						<InputNumber min={0} max={6} defaultValue={0} onChange={e => setStage(e)} />
					</div>
				</div>
				<List
					itemLayout="horizontal"
					dataSource={questions}
					renderItem={(item) => {
						const { question, index, answer } = item;
						return (
							<List.Item
								actions={[
									<Radio.Group
										options={[
											{
												label: 'Yes',
												value: 'yes'
											},
											{
												label: 'No',
												value: 'no'
											}
										]}
										value={answer}
										onChange={(value) => onChange(index, value)}
									/>
								]}
							>
								{question}
							</List.Item>
						)
					}}
				/>
			</Modal>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
				rowSelect={true}
			/>
			<Modal
				title='Хэрэглэгчийн дэлгэрэнгүй'
				open={modalData !== null}
				onCancel={() => {
					setModalData(null)
				}}
				footer={null}
				width={800}
			>
				{modalData?.data?.map((userID) => {
					if (userID.key === "userId") {
						return (
							<Link
								to={{
									pathname: "/scholarship/cv",
									state: {
										id: userID.value,
									},
								}}
								key={userID.value}
							>
								<Button type="primary">CV харах</Button>
							</Link>
						)
					}
					return null
				})}
				<CustomForm
					fields={modalData?.data}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
				/>
			</Modal>
		</>
	)
}

export default ScholarshipUsers;
