import * as React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_permissions';

const Permissions = () => {

    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [tableState, setTableState] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

    React.useEffect(() => {
        const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = (params) => {
        setData([]);
        axios
            .post(
                "/api/permission/list",
                params,
            )
            .then(({ data }) => {
                const {
                    list,
                    columns: cols,
                    rest: { totalDocs = 1, limit = 20 } = {},
                } = getFormattedResponse(data);
                setColumns(cols);
                setData(list);
                setTableState((prevState) => {
                    return {
                        ...prevState,
                        totalSize: totalDocs,
                        loading: false,
                        pageSize: limit,
                    }
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            query: search,
            sort,
            order
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

    const getColumns = () => {
        const formattedColumns = columns.map(col => {
            let render;
            if (col.key === "model") {
                render = (text, record) => {
                    return (
                        <Link
                            to={{
                                pathname: "/permission/edit",
                                state: {
                                    id: record._id,
                                },
                            }}
                        >
                            {text}
                        </Link>
                    );
                }
            }
            return {
                ...col,
                render,
            }
        })

        const additionalColumns = [
            {
                key: "action",
                width: 1,
                sorter: false,
                fixed: 'right',
                render: (text, record) => (
                    <Space size="middle" align="center">
                        <Link
                            to={{
                                pathname: '/permission/edit',
                                state: {
                                    id: record._id
                                },
                            }}
                        >
                            <EditOutlined />
                        </Link>
                    </Space>
                ),
            },
        ];

        return [
            ...formattedColumns,
            ...additionalColumns
        ];
    };

    return (
        <PageContent
            subheader={{
                title: 'Өгөгдөлд хандах эрх',
                background: '#ffffff'
            }}
        >
            <CustomTable
                bordered
                prefix={tablePrefix}
                dataSource={data}
                columns={getColumns()}
                rowKey='_id'
                showAll={false}
                showFilter={false}
                onInteract={handleInteraction}
                state={tableState}
            />
        </PageContent>
    )
};

export default Permissions;