import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import * as Icon from "@ant-design/icons";
import dashboardRoutes from "../../routes/DashboardRoutes";

const SideMenu = ({ menu = [] }) => {

    const { pathname = "" } = useLocation();
    const [ openKeys, setOpenKeys ] = React.useState([]);

    const getPathCode = () => {
        const route = dashboardRoutes?.find?.(route => route.path === pathname);
        if (route?.code) {
            return route.code;
        }
        return null;
    }

    const pathCode = getPathCode();

    const generateIcon = (name) => {
        const element = React.createElement(Icon?.[name] ?? Icon.SettingOutlined, {
            style: { fontSize: 12 },
        });
        return element;
    };

    const getOpenRouteCodes = (array, code, codes = []) => {
        const updatedCodes = [...codes, code];
        const route = array.find(item => item.code === code);
        if (route?.parent) {
            return getOpenRouteCodes(array, route.parent, updatedCodes);
        }
        return updatedCodes;
    }

    React.useEffect(() => {
        // setOpenKeys(getDefaultOpenKeys());
        setOpenKeys(getOpenRouteCodes(dashboardRoutes, pathCode));
    }, [menu, pathname, pathCode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenChange = keys => {
        setOpenKeys(keys);
    }

    return (
        <Menu
            selectedKeys={openKeys}
            openKeys={openKeys}
            mode="inline"
            theme="light"
            style={{ border: "none" }}
            onOpenChange={handleOpenChange}
            items={
                menu?.map?.((item) => {
                    if (item.children) {
                        return {
                            key: item.code,
                            icon: generateIcon(item.icon),
                            label: item.name,
                            children: item.children.map((subItem) => {
                                return {
                                    key: subItem.code,
                                    icon: generateIcon(subItem.icon),
                                    title: subItem.name,
                                    label: <Link to={"/" + subItem.code}>{subItem.name}</Link>
                                };
                            })
                        };
                    } else {
                        return {
                            key: item.code,
                            icon: generateIcon(item.icon),
                            title: item.name,
                            label: <Link to={"/" + item.code}>{item.name}</Link>
                        };
                    }
                }
            )}
        />
    );
};

export default SideMenu;
