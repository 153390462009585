import * as React from 'react';

const browser = typeof window !== 'undefined';
const defaultState = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};
export function useResizeObserver() {
    const frameID = React.useRef(0);
    const ref = React.useRef(null);

    const [rect, setRect] = React.useState(defaultState);

    const observer = React.useMemo(
        () =>
            browser
                ? new ResizeObserver((entries) => {
                    const entry = entries[0];

                    if (entry) {
                        cancelAnimationFrame(frameID.current);

                        frameID.current = requestAnimationFrame(() => {
                            if (ref.current) {
                                setRect(entry.contentRect);
                            }
                        });
                    }
                })
                : null,
        []
    );

    React.useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();

            if (frameID.current) {
                cancelAnimationFrame(frameID.current);
            }
        };
    }, [ref, observer]);

    return [ref, rect];
}

export function useElementSize() {
    const [ref, { width, height }] = useResizeObserver();
    return { ref, width, height };
}