import { Button, Space, Form, Input, Typography } from "antd";
import { MailOutlined, KeyOutlined } from "@ant-design/icons";
import logo from "../../assets/img/logo.svg";
import "./style.less";

const { Title, Text, Paragraph } = Typography;

const Login = ({
    onFinish,
    loading,
    loginTypeChange
}) => {
    return (
        <div className="login-card">
            <Space direction="vertical" size={0} className="login-space">
                <img
                    src={logo}
                    className="login-logo"
                    alt="Welcome"
                />
                <Paragraph>
                    <Text>Тавтай морил</Text>
                    <Title level={2} style={{ marginTop: 0 }}>
                        Та нэвтэрч орно уу
                    </Title>
                </Paragraph>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Нэвтрэх нэрээ оруулна уу!",
                            },
                        ]}
                    >
                        <Input
                            style={{ padding: "0.7rem 0.7rem" }}
                            size="large"
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            placeholder="Нэвтрэх нэр"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Нууц үгээ оруулна уу!",
                            },
                        ]}
                    >
                        <Input
                            style={{ padding: "0.7rem 0.7rem" }}
                            size="large"
                            prefix={<KeyOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Нууц үг"
                            allowClear
                        />
                    </Form.Item>
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "3rem",
                            marginBottom: "3rem",
                        }}
                    >
                        <Button
                            type='link'
                            onClick={() => loginTypeChange('forgot')}
                        >
                            Нууц үгээ мартсан уу?
                        </Button>
                    </div>

                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            className="login-form-button"
                            loading={loading}
                        >
                            Нэвтрэх
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    );
};

export default Login;
