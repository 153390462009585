import * as React from "react";
import moment from 'moment';
import { Table, Tooltip, Select, Input } from "antd";
import { CheckCircleFilled, CloseCircleFilled, ClockCircleFilled, CheckSquareOutlined, CloseSquareOutlined, SafetyCertificateFilled } from '@ant-design/icons';
import { saveTableState } from "../../utils/localStorage";
import SearchInput from "./SearchInput";
import "./customtable.less";
import { actions, reducer, initialState } from "./helper";
import { getFormattedNumber } from "../../utils";
import FilterCheckbox from "./FilterCheckbox";

const { Option } = Select;

const CustomTable = ({
	showAll = true,
	showFilter = true,
	showPagination = false,
	onInteract = () => undefined,
	prefix = "", // Used to save table state into local storage.
	state: { pageSize = 10, page = 1, loading = false, totalSize = 1, sort = null, order = "ascend" } = {},
	columns = [],
	columnFilters: columnFilterKeys = [],
	filters = [],
	rowSelect = false,
	...props
}) => {
	const headerRow = React.useRef();
	const filterRow = React.useRef();
	const tableWrapper = React.useRef();

	const [ state, dispatch ] = React.useReducer(reducer, initialState, undefined);
	const [ selectedRowKeys, setSelectedRowKeys ] = React.useState([]);

	React.useEffect(() => {
		// setTimeout(() => {
		// 	if (tableWrapper?.current?.offsetHeight) {
		// 		dispatch({
		// 			type: actions.setState,
		// 			payload: {
		// 				key: 'height',
		// 				value: tableWrapper.current.offsetHeight,
		// 			}
		// 		})
		// 	}
		// });
		new ResizeObserver((entries) => {
			dispatch({
				type: actions.setState,
				payload: {
					key: 'height',
					value: entries[0].contentRect.height
				}
			})
		}).observe(tableWrapper.current);
	}, []);

	React.useEffect(() => {
		const tableHeader = headerRow.current;
		const headerFilterRow = filterRow.current;

		if (tableHeader) {
			new ResizeObserver(() => {
				const newHeaderHeight = tableHeader.getBoundingClientRect()?.height ?? 0;
				if (newHeaderHeight) {
					dispatch({
						type: actions.setState,
						payload: {
							key: 'headerHeight',
							value: newHeaderHeight + 1
						}
					})
				}
			}).observe(tableHeader);
		}

		if (headerFilterRow) {
			new ResizeObserver(() => {
				const newFilterRowHeight = headerFilterRow.getBoundingClientRect()?.height ?? 0;
				if (newFilterRowHeight) {
					dispatch({
						type: actions.setState,
						payload: {
							key: 'filterRowHeight',
							value: newFilterRowHeight + 1
						}
					})
				}
			}).observe(headerFilterRow);
		}
	}, [headerRow?.current, filterRow?.current]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		const updatedColumnFilters = {};
		const currentKeys = [];
		for (const key in state.columnFilters) {
			currentKeys.push(key);
		}
		let hasDifference = false;
		for (const currentKey of currentKeys) {
			if (!columnFilterKeys.includes(currentKey)) {
				hasDifference = true;
			}
		}
		if (hasDifference) {
			if (columnFilterKeys === 'all') {
				for (const col of columns) {
					updatedColumnFilters[col.key] = state.columnFilters[col.key] || '';
				}
			} else {
				columnFilterKeys.map(key => {
					return (updatedColumnFilters[key] = state.columnFilters[key] || '');
				})
			}
			dispatch({
				type: actions.setColumnFilters,
				payload: {
					columnFilters: updatedColumnFilters
				}
			})
		}
	}, [ columnFilterKeys ]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (Object.keys(state.tableFilters).length) {
			handleInteract({
				filters: state.tableFilters,
				page: 1
			});
		}
	}, [state.tableFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	const paginationConfig = showAll
		? false
		: {
			total: totalSize,
			showQuickJumper: true,
			pageSize: showAll ? totalSize : pageSize,
			current: page,
			hideOnSinglePage: true,
			pageSizeOptions: ['10', '20', '50', '100'],
			onChange(pageNumber, size) {
				saveTableState(prefix, 'page', pageNumber);
				saveTableState(prefix, 'size', size);
				handleInteract({
					page: pageNumber,
					pageSize: size,
				});
			},
			...props.pagination,
		};

	const paginationHeight = paginationConfig ? 64 : 0;
	const searchInputHeight = showFilter ? 60 : 0;
	const tableHeight = state.height - paginationHeight - state.headerHeight - searchInputHeight - state.filterRowHeight;

	const handleSearchChange = (value) => {
		dispatch({
			type: actions.setState,
			payload: {
				key: 'searchValue',
				value
			}
		})
	};

	const onSearch = (value) => {
		handleInteract({
			search: value,
			page: 1,
		});
	};

	const handleInteract = (updatedStates = {}) => {
		onInteract({
			page,
			pageSize,
			search: state.searchValue,
			sort,
			order,
			columnFilters: state.columnFilters,
			filters: state.tableFilters,
			...updatedStates,
		});
	};

	const handleTableChange = (pagination, filters, sorter, extra) => {
		if (extra?.action === "sort") {
			const { columnKey = null, order = "ascend" } = sorter;
			handleInteract({
				sort: columnKey,
				order,
				page: 1,
			});
		}
	};

	const handleColumnFilterChange = (key, value) => {
		dispatch({
			type: actions.setColumnFilters,
			payload: {
				columnFilters: {
					...state.columnFilters,
					[key]: value,
				}
			}
		})
	}

	const handleFilterInputKeyDown = event => {
		if (event.key === 'Enter') {
			handleInteract({
				page: 1,
			})
		}
	}

	const tableColumns = columns.map((col) => {
		let sortOrder = false;
		if (sort === col.dataIndex) {
			sortOrder = order;
		}
		let render = undefined;

		const children = columnFilterKeys === 'all' || columnFilterKeys.includes(col.key)
		?	[
			{
				dataIndex: col.key,
				title: (
					<input
						style={{ width: '100%' }}
						value={state.columnFilters[col.key] || ''}
						onChange={e => handleColumnFilterChange(col.key, e.target.value)}
						onKeyDown={handleFilterInputKeyDown}
					/>
				)
			},
		]
		:	columnFilterKeys.length
			?	[
					{
						dataIndex: col.key,
						title: null
					},
				]
			:	 undefined;

		if (col.instance === 'datetime') {
			render = (text) => {
				return moment(text).format('YYYY/MM/DD - HH:mm:ss');
			}
		}

		if (col.dataIndex === 'progress') {
			render = (text) => {
				switch(text) {
					case 'accepted': return <CheckCircleFilled style={{ color: "green", fontSize: "20px", textAlign: "center", width: "100%" }} />
					case 'waiting': return <ClockCircleFilled style={{ color: "orange", fontSize: "20px", textAlign: "center", width: "100%" }} />
					case 'notAccepted': return <CloseCircleFilled style={{ color: "red", fontSize: "20px", textAlign: "center", width: "100%" }} />
					case 'worked': return <SafetyCertificateFilled style={{ color: "dodgerBlue", fontSize: "20px", textAlign: "center", width: "100%" }} />
					default: return <div>-</div>
				}
			}
		}

		if (col.dataIndex === 'shortAnswer') {
			render = (text) => {
				switch(text) {
					case 'yes': return <CloseSquareOutlined style={{ color: "red", fontSize: "20px", textAlign: "center", width: "100%" }} />
					case 'no': return <CheckSquareOutlined style={{ color: "green", fontSize: "20px", textAlign: "center", width: "100%" }} />
					default: return <div>-</div>
				}
			}
		}

		if (col.instance === 'date') {
			render = (text) => {
				return moment(text).format('YYYY/MM/DD');
			}
		}

		if (col.instance === 'time') {
			render = (text) => {
				return moment(text).format('HH:mm:ss');
			}
		}

		if (col.instance === 'number') {
			render = (text) => {
				return getFormattedNumber(text) ?? '-';
			}
		}

		return {
			...col,
			sorter: col.sorter !== false,
			sortOrder,
			ellipsis: true,
			showSorterTooltip: true,
			className: 'table-cell',
			title: (
				<Tooltip title={col.name ?? '-'}>
					<div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{col.name || null}</div>
				</Tooltip>
			),
			children,
			render: col?.render ?? render,
		};
	});

	const handleFilterItemChange = (object, value) => {
		dispatch({
			type: actions.tableFilterItemChange,
			payload: {
				key: object.key,
				value
			}
		})
	};

	const getFilterItem = object => {
		const key = `filter-${object.key}`;
		switch (object.type) {
			case 'input': {
				return (
					<div className='table-filter-item' key={key}>
						<div style={{ fontSize: 12, color: '#777777' }}>
							{object.name || '-'}
						</div>
						<Input
							key={key}
							onChange={e => handleFilterItemChange(object, e.target.value)}
							value={state.tableFilters[object.key] ?? object.defaultValue ?? ''}
						/>
					</div>
				)
			}
			case 'select': {
				return (
					<div className='table-filter-item' key={key}>
						<div style={{ fontSize: 12, color: '#777777' }}>
							{object.name || '-'}
						</div>
						<Select
							style={{ width: 150 }}
							onChange={val => handleFilterItemChange(object, val)}
							value={state.tableFilters[object.key] ?? object.defaultValue ?? null}
						>
							{
								(object?.enum ?? []).map(enumValue => {
									return (
										<Option value={enumValue} key={enumValue}>
											{object?.enumDictionary?.[enumValue] ?? enumValue}
										</Option>
									)
								})
							}
						</Select>
					</div>
				)
			}
			case 'checkbox': {
				const checkboxItems = object?.enum?.map(enumValue => {
					return {
						key: enumValue,
						label: object?.enumDictionary?.[enumValue] ?? enumValue,
						checked: state?.tableFilters?.[object?.key]?.includes?.(enumValue) || false,
					}
				})
				return (
					<FilterCheckbox
						items={checkboxItems}
						key={key}
						title={object?.name}
						onChange={checkboxKey => {
							dispatch({
								type: actions.tableFilterCheckboxChange,
								payload: {
									checkboxKey,
									filterItemKey: object?.key
								}
							})
						}}
					/>
				)
			}
			default:
				return null;
		}
	}

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
		localStorage.removeItem('selectedRow');
		localStorage.setItem('selectedRow', JSON.stringify(newSelectedRowKeys));
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange
	};

	return (
		<div ref={tableWrapper} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{
					showFilter && (
						<div style={{ marginRight: 15, marginLeft: 15, marginTop: 18 }}>
							<SearchInput value={state.searchValue} onChange={handleSearchChange} onSearch={onSearch} />
						</div>
					)
				}
				{
					filters.length > 0 && (
						<div style={{ display: 'flex', width: '100%' }}>
							<div className='table-filter-items-container'>
								{filters.map(getFilterItem)}
							</div>
						</div>
					)
				}
			</div>
			<Table
				loading={loading}
				rowSelection={rowSelect ? rowSelection : false}
				columns={tableColumns}
				onChange={handleTableChange}
				onHeaderRow={(columns, index) => {
					const ref = index === 0 ? headerRow : filterRow;
					return {
						ref
					}
				}}
				size='small'
				{...props}
				pagination={paginationConfig}
				scroll={{ x: true, y: tableHeight }}
			/>
		</div>
	);
};

export default CustomTable;
