import * as React from "react";
import axios from "axios";
import moment from 'moment';
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_students';

const Students = () => {

	const [ data, setData ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

  	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize
        });
  	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios
			.post(
				"/api/student/list",
				params,
			)
			.then(({ data }) => {
				const { 
					list,
					columns: cols,
					rest: { totalDocs = 1, limit = 20 } = {},  
				} = getFormattedResponse(data);
				setColumns(cols);
				setData(list);
				setTableState((prevState) => {
					return {
						...prevState,
						totalSize: totalDocs,
						loading: false,
						pageSize: limit,
					}
				});
			})
			.catch((e) => {
				console.log(e);
			});
  	};

  	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            query: search,
            sort,
            order
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
			if (['birthDate'].includes(col.key)) {
                return {
                    ...col,
                    render(text) {
                        if (text) {
                            return moment(text).format('YYYY-MM-DD')
                        };
                        return '-'
                    },
                }
            }
			if (['phoneNumber', 'gender'].includes(col.key)) {
                return {
                    ...col,
                    render(text) {
                        if (text) {
                            return text
                        };
                        return '-'
                    },
                }
            }
            return {
                ...col,
                render,
            }
		})

		return [
			...formattedColumns
		];
	};

	return (
		<PageContent
			subheader={{
				title: 'Сурагч',
				background: '#ffffff'
			}}
		>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
	  	</PageContent>
	)
};

export default Students;