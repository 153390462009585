import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
    return (
        <div className='app-loading-container'>
            <div className='app-loading-background'/>
            <div className='app-loading-spinner'>
                <Spin className='app-loading' size='large'/>
            </div>
        </div>
    )
}

export default Loading;