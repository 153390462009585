import * as React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';
import { handleFormImageUpload } from '../../utils';

const AddEvent = () => {

	const [ fields, setFields ] = React.useState([]);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/event`)
		.then(({ data = {} }) => {
			const { result = [] } = data;
			const responseFields = (result ?? []).map((field) => {
				if (field.key === 'title') {
					return {
						...field,
						customProps: {
							extra: '92 тэмдэгтээс хэтэрсэн тохиолдолд аппликейшнд "..." тэмдэгээр хязгаарлагдахыг анхаарна уу!'
						}
					}
				}
				return {
					...field
				};
			});
			setFields(responseFields);
		})
		.catch(err => {
			console.log(err);
		})
	}, []);

	const handleSubmit = values => {
		axios.post(`/api/event`, values)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/event');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/event')
	}

	return (
		<PageContent 
			background='#fff'
			subheader={{
				title: 'Эвент үүсгэх',
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={fields}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				imageUpload={handleFormImageUpload}
			/>
		</PageContent>
	)
}

export default AddEvent;