import * as React from "react";
import axios from "axios";
import { Select, DatePicker } from "antd";
import PageContent from "../../components/layout/PageContent";
import { getTableState } from "../../utils/localStorage";
import { getFormattedResponse } from "../../utils";
import CustomTable from "../../components/table/CustomTable";

const tablePrefix = 'config_membership';
const { RangePicker } = DatePicker;

const Memberships = () => {
	const [ data, setData ] = React.useState([]);
	const [ columns, setColumns ] = React.useState([]);
	const [ time, setTime ] = React.useState("");
	const [ type, setType ] = React.useState("");
	const [ tableState, setTableState ] = React.useState(() => {
        const {
            pageSize = 20,
            page = 1,
            loading = false,
            totalSize = 1
        } = getTableState(tablePrefix);
        return {
            pageSize,
            page,
            loading,
            totalSize,
        }
    });

	React.useEffect(() => {
		const { page, pageSize } = tableState;
        fetchData({
            page,
            pageSize,
			paymentType: type,
			startDate: time?.[0],
			endDate: time?.[1]
        });
  	}, [type, time]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = (params) => {
		setData([]);
		axios
			.post(
				"/api/membership-renewals/list",
				params
			)
			.then(({ data }) => {
				const { 
					list, 
					columns: cols,
					rest: { totalDocs = 1, limit = 20 } = {},  
				} = getFormattedResponse(data);
				setColumns(cols);
				setData(list);
				setTableState((prevState) => {
					return {
						...prevState,
						totalSize: totalDocs,
						loading: false,
						pageSize: limit
					}
				});
			})
			.catch((e) => {
				console.log(e);
			});
  	};

  	const handleInteraction = ({ page, pageSize, search, sort, order }) => {
        const params = {
            page,
            pageSize: pageSize,
            email: search,
            sort,
            order,
			paymentType: type,
			startDate: time?.[0],
			endDate: time?.[1]
        };
        setTableState({
            ...tableState,
            page,
            pageSize,
            loading: true,
            sort,
            order
        });
        fetchData(params);
    };

	const getColumns = () => {
		const formattedColumns = columns.map(col => {
			let render;
            return {
                ...col,
                render
            }
		})

		return [
			...formattedColumns
		];
	};

	return (
		<PageContent
			subheader={{
				title: 'Гишүүнчлэл',
				background: '#ffffff',
			}}
		>
			<div style={{ display: 'flex', marginLeft: '328px', marginBottom: '-50px' }}>
				<Select
					defaultValue="Төлбөр"
					style={{
						width: 150,
						marginRight: "10px"
					}}
					onChange={e => { setType(e) }}
					options={[
						{
							value: '',
							label: 'Бүгд'
						},
						{
							value: 'gift_card',
							label: 'Бэлгийн карт'
						},
						{
							value: 'promo',
							label: 'Промо код'
						},
						{
							value: 'qpay',
							label: 'Q pay'
						},
						{
							value: 'socialpay',
							label: 'Social Pay'
						}
					]}
				/>
				<RangePicker
					onChange={e => { setTime(e) }}
				/>
			</div>
			<CustomTable
				bordered
				prefix={tablePrefix}
				dataSource={data}
				columns={getColumns()}
				rowKey='_id'
				showAll={false}
				onInteract={handleInteraction}
				state={tableState}
			/>
	  	</PageContent>
	)
};

export default Memberships;