import { Node, nodeInputRule } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ImageComponent from "./ImageComponent";
import { getImageAttributes, getImagePosition } from "../utils";

export const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))/;

export const ImageExtension = Node.create({
	name: "image",
	draggable: true,
	addOptions() {
		return {
			inline: false,
			HTMLAttributes: {},
		};
	},
	addNodeView() {
		return ReactNodeViewRenderer(ImageComponent);
	},
	inline() {
		return this.options.inline;
	},
	group() {
		return this.options.inline ? "inline" : "block";
	},
	addAttributes() {
		return {
			data: {
				default: [],
			},
			caption: {
				default: '',
			},
			layout: {
				default: 'grid',
				// renderHTML: attrs => {
				// 	return {
				// 		'data-layout': 'grid'
				// 	}
				// }
			},
			tags: {
				default: []
			},
			grid: {
				default: {
					row: 1,
					col: 1
				}
			},

			style: {
				default: null,
			},
		};
	},
	parseHTML() {
		return [
			{
				tag: "img[src]",
			},
		];
	},
	renderHTML({ HTMLAttributes }) {
		const whitelist = ['src', 'alt', 'style'];
		const blacklist = ['data', 'style'];
		const value = ['div'];
		if (HTMLAttributes?.data?.length) {
			for (const imageObject of HTMLAttributes.data) {
				const attributes = {
					...getImageAttributes(imageObject, whitelist, blacklist),
					...getImageAttributes(HTMLAttributes, whitelist, blacklist),
				};
				
				value.push(['img', attributes]);
			}
		}
		return value;
	},
	addCommands() {
		return {
			setImage: ({ images }) => ({ chain }) => {
				let lastRow = 0;
				const data = images.map((image, index, array) => {
					const position = getImagePosition(index, array.length, 'grid');
					if (lastRow < position.colStart) {
						lastRow = position.colStart;
					};

					return {
						alt: 'Image',
						...image,
						position,
					}
				});
				return chain().insertContent([
					{
						type: this.name,
						attrs: {
							data,
							grid: {
								row: lastRow,
								col: 6,
							}
						}
					},
					{
						type: 'paragraph'
					}
				]);
			},
		};
	},
	// parseHTML() {
	// 	return [
	// 		{
	// 			tag: 'img',
	// 			getAttrs: element => {
	// 				console.log('el>>', element);
	// 			}
	// 		}
	// 	]
	// },
	addInputRules() {
		return [
			nodeInputRule({
				find: inputRegex,
				type: this.type,
				getAttributes: (match) => {
					const [, , alt, src, title] = match;
					return { src, alt, title };
				},
			}),
		];
	},
});