export const actions = {
    setState: 'SET_STATE',
    setColumnFilters: 'SET_COLUMN_FILTERS',
    tableFilterItemChange: 'TABLE_FILTER_ITEM_CHANGE',
}

export const initialState = {
    height: 1,
    headerHeight: 1,
    filterRowHeight: 1,
    searchValue: '',
    columnFilters: {},
    tableFilters: {},
}

export const reducer = (state, action) => {
    switch(action.type) {

        case actions.setState: {
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: value,
            }
        }

        case actions.setColumnFilters: {
            const { columnFilters } = action.payload;
            return {
                ...state,
                columnFilters,
            }
        }

        case actions.tableFilterItemChange: {
            const { key, value } = action.payload;
            const { tableFilters } = state;

            return {
                ...state,
                tableFilters: {
                    ...tableFilters,
                    [key]: value,
                }
            }
        }

        default:
            return state;
    }
}