import * as React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Input } from 'antd';
import PageContent from '../../components/layout/PageContent';
import CustomForm from '../../components/form/CustomForm';

const UserEdit = ({
	location: { state: { id = null } = {} } = {},
}) => {

	const [ fields, setFields ] = React.useState([]);
	const [ pw, setPW ] = React.useState(undefined);

	const history = useHistory();

	React.useEffect(() => {
		axios.get(`/api/user/${id}`)
		.then(({ data = {} }) => {
			const { result = {  } } = data;
			const updated = (result?.data ?? []).map(field => {
				return {
					...field
				};
			})
			setFields(updated);
		})
		.catch(err => {
			console.log(err);
		})
	}, [id]);

	const handleSubmit = (fields) => {
		const params = {
			...fields,
		}
		axios.post(`/api/user/${id}`, params)
		.then(({ data }) => {
			if (data.statusCode === 200) {
				history.push('/user');
			}
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handleCancel = () => {
		history.push('/user')
	};

	// const getMaskedPW = string => {
	// 	console.log('*'.repeat(string.length));
	// 	return '*'.repeat(string.length);
	// };
	
	const getFields = () => {
		return fields.map(field => {
			if (field.key === 'password') {
				return {
					...field,
					instance: 'custom',
					component: (
						<Input
						type='password'
						onChange={e => {
							if (pw === undefined) {
								setPW(e.currentTarget.value.slice(-1));
							} else {
								setPW(e.currentTarget.value);
							}
						}}
						/>
					),
					value: pw === undefined ? field.value : pw,
					// maskedValue: pw,
				}
			}
			return {
				...field,
			}
		})
	}

	return (
		<PageContent
			background='#ffffff'
			subheader={{
				title: 'Системийн хэрэглэгч засах',
				background: '#ffffff'
			}}
		>
			<CustomForm
				fields={getFields()}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</PageContent>
	)
}

export default UserEdit;