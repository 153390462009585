export const getNew = (currentState) => {
    const state = {};
    for (const key in currentState) {
        if (currentState.hasOwnProperty(key)) {
            const val = currentState[key];
            const arr = val.map(element => {
                const object = {};
                const subArray = [];
                let k = undefined;
                for (const subElement of element) {
                    k = subElement.key;
                    if (subElement.instance === 'array' && subElement.arrayType === 'embedded') {
                        const subObject = {};
                        for (const q of subElement.embedded) {
                            subObject[q.key] = q.value;
                        };
                        subArray.push(subObject);
                    } else {
                        object[subElement.key] = subElement.value;
                    }
                };
                object[k] = subArray;
                return object;
            });
            state[key] = arr;
        }
    };
    return state;
};

export const validateImage = (object) => {
    let file = null;
    if (object.originFileObj) {
        file = object.originFileObj;
    } else {
        file = object;
    };
    if (!file) {
        return false;
    }
    const MAX_UPLOAD_SIZE = 5_242_880; // in bytes
    if (file.size <= MAX_UPLOAD_SIZE) {
        return true;
    };
    return false;
}