import * as React from 'react';
import { Layout } from 'antd';
import appConfig from '../../appConfig';
import { Logo, Profile } from '../common';
import './header.less';

const Header = () => {
	const { Header: AntHeader } = Layout;
	const [ companyLogo, setCompanyLogo ] = React.useState('');
	const [ name, setName ] = React.useState("");

	React.useEffect(() => {
		setCompanyLogo(localStorage.getItem("company_logo"));
		setName(localStorage.getItem("first_name"));
	}, []);

	const width = appConfig?.layout?.sideMenu?.width ?? undefined;

	// TODO: improve styling and stuff later on...

	return (
		<AntHeader className="header">
			<div className='logo-wrapper' style={{ width }}>
				<Logo companyLogo={companyLogo} company={"Tetgeleg"} hide={false} />
			</div>
			<div className='header-content-container' style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
				<div />
				<Profile avatar={companyLogo} name={name} />
			</div>
		</AntHeader>
	)
}

export default Header;